import {Box, Button} from "@mui/material";
import {useAuth} from "react-oidc-context";
import {Navigate} from "react-router-dom";
import React from "react";
import Header from "../components/Header";
import {useTranslation} from 'react-i18next'


import {AUTH_ENDPOINT} from "../config/Env";

const Login = () => {
    const auth = useAuth();
    const {t} = useTranslation();

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }
    if (auth.error) {
        console.log("auth error: " + auth.error.message)
    }
    if (!auth.isAuthenticated) {
        console.log("no auth error")
    }
    if (auth.isAuthenticated) {
        console.log("auth ok")
        return <Navigate to="/" replace/>;
    }

    return (
        <Box m="20px">
            <div>
                <Header
                    title="FWB Options"
                    subtitle={t("messages.login.redirect") + " " + AUTH_ENDPOINT}
                />
                <Box display="flex" justifyContent="space-between" mt="20px">
                    <Box>
                        <Button type="submit" color="button" variant="contained"
                                onClick={() => void auth.signinRedirect()}>
                            Log in
                        </Button>
                    </Box>
                </Box>
            </div>
        </Box>
    );
};

export default Login;
