import {useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, TextField, useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from "@mui/material/MenuItem";
import {Formik} from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import {tokens} from "../../theme";
import axios from "axios";
import {useAuth} from "react-oidc-context";

const UsersEditOrCreate = () => {
    const [formData, setFormData] = useState(null);
    const [formSaved, setFormSaved] = useState(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const routeParams = useParams();
    const id = routeParams.id;
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const urlId = apiEndpoint + "/users/" + id;
    const url = apiEndpoint + "/users";
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const auth = useAuth();
    useEffect(() => {
        const authToken = auth.user.access_token;
        const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
        if (id) {
            axios.get(urlId, authConfig)
                .then((response) => {
                    console.log(response);
                    setFormData(response.data);
                }, (error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        auth.signinRedirect();
                    }
                });
        } else {
            setFormData(null);
        }
    }, [id, urlId])

    console.log("user id: " + id);
    console.log(formData)

    const accessLevels = [
        {
            value: 'admin',
            label: 'admin',
        },
        {
            value: 'manager',
            label: 'manager',
        },
        {
            value: 'user',
            label: 'user',
        }
    ];

    function sendAjax(url, values, method) {
        const authToken = auth.user.access_token;
        axios({
            method: method,
            url: url,
            data: values,
            headers:  {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authToken
            }
        }).then((response) => {
            console.log(response);
            setFormData(response.data);
            setFormSaved(true);
            console.log(formSaved);
        }, (error) => {
            console.log(error);
            setFormSaved(false);
            console.error('There was an error!', error);
            if (error.response.status === 401) {
                auth.signinRedirect();
            }
        });
    }

    const handleFormReset = () => {
        setFormData(null);
    };

    const handleFormDelete = (id) => {
        sendAjax(urlId, formData, "DELETE");
        setFormData(null);
        navigate(-1);
    };

    return (
        <Box m="20px">
            {id ? (<Header title="Edit User" subtitle="Edit User Profile"/>) :
                (<Header title="Create User" subtitle="Create New User Profile"/>)
            }
            <Box
                sx={
                    {
                        "& input[type='date']::-webkit-calendar-picker-indicator": {
                            filter: 'invert(0.5)',
                        },
                        "& input[type='time']::-webkit-calendar-picker-indicator": {
                            filter: 'invert(0.5)',
                        },
                        "& .Mui-focused": {
                            color: colors.greenAccent[300],
                        },
                        "& label.Mui-focused": {
                            color: colors.greenAccent[300],
                        },
                        "& button.delete": {
                            backgroundColor: colors.redAccent[300],
                        },
                    }}
            >
                <Formik
                    onSubmit={(values, event) => {
                        if (id) {
                            sendAjax(urlId, values, "PUT");
                        } else {
                            sendAjax(url, values, "POST");
                        }

                    }}
                    enableReinitialize
                    initialValues={formData || initialValues}
                    validationSchema={checkoutSchema}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          isSubmitting,
                          handleSubmit,
                          dirty,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                                }}
                            >
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="First Name"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstName || ""}
                                    name="firstName"
                                    error={!!touched.firstName && !!errors.firstName}
                                    helperText={touched.firstName && errors.firstName}
                                    sx={{gridColumn: "span 2"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Last Name"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastName || ""}
                                    name="lastName"
                                    error={!!touched.lastName && !!errors.lastName}
                                    helperText={touched.lastName && errors.lastName}
                                    sx={{gridColumn: "span 2"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Age"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.age || ""}
                                    name="age"
                                    error={!!touched.age && !!errors.age}
                                    helperText={touched.age && errors.age}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    id="filled-select-access-level"
                                    select
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Access Level"
                                    InputLabelProps={{shrink: true}}
                                    defaultValue="user"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.accessLevel || ""}
                                    name="accessLevel"
                                    error={!!touched.accessLevel && !!errors.accessLevel}
                                    helperText={touched.accessLevel && errors.accessLevel}
                                    sx={{gridColumn: "span 1"}}
                                >
                                    {accessLevels.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Uid"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.uid || ""}
                                    name="uid"
                                    error={!!touched.uid && !!errors.uid}
                                    helperText={touched.uid && errors.uid}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Id"
                                    disabled
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.id || ""}
                                    name="id"
                                    error={!!touched.id && !!errors.id}
                                    helperText={touched.id && errors.id}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Email"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email || ""}
                                    name="email"
                                    error={!!touched.email && !!errors.email}
                                    helperText={touched.email && errors.email}
                                    sx={{gridColumn: "span 2"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Phone"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.phone || ""}
                                    name="phone"
                                    error={!!touched.phone && !!errors.phone}
                                    helperText={touched.phone && errors.phone}
                                    sx={{gridColumn: "span 2"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    InputLabelProps={{shrink: true}}
                                    label="City"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.city || ""}
                                    name="city"
                                    error={!!touched.city && !!errors.city}
                                    helperText={touched.city && errors.city}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    InputLabelProps={{shrink: true}}
                                    label="Zip Code"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.zipCode || ""}
                                    name="zipCode"
                                    error={!!touched.zipCode && !!errors.zipCode}
                                    helperText={touched.zipCode && errors.zipCode}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    InputLabelProps={{shrink: true}}
                                    label="Address"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.address || ""}
                                    name="address"
                                    error={!!touched.address && !!errors.address}
                                    helperText={touched.address && errors.address}
                                    sx={{gridColumn: "span 2"}}
                                />
                            </Box>
                            <Box display="flex" justifyContent="space-between" mt="20px">
                                <Box>
                                    <Button type="submit" color="button" variant="contained" disabled={!dirty}>
                                        {id ? "Save User" : "Create New User"}
                                    </Button>
                                </Box>
                                <Box>
                                    <Button type="reset" color="caution" variant="contained" onClick={handleFormReset}
                                            sx={{marginRight: "20px"}}>
                                        Reset
                                    </Button>
                                    <Button type="button" color="caution" variant="contained"
                                            onClick={() => handleFormDelete(id)} disabled={!id}>
                                        Delete
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    uid: yup.string().required("required"),
    age: yup.number().required("required"),
    accessLevel: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
});

const initialValues = {
    firstName: "",
    lastName: "",
    age: "",
    email: "",
    uid: "",
    accessLevel: "user"
};

export default UsersEditOrCreate;
