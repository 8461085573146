import React, {useEffect, useState} from "react";
import {Link as RouteLink} from "react-router-dom";
import {Box, Typography, useTheme} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import {useAuth} from "react-oidc-context";
import axios from "axios";

const OptionsClosed = () => {
    const [tableData, setTableData] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const url = apiEndpoint + "/optionsClosed";

    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        axios.get(url, authConfig)
            .then((response) => {
                console.log(response);
                setTableData(response.data);
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [])
    console.log(tableData)

    const columns = [
        {
            field: "action",
            headerName: "",
            flex: 0.4,
            renderCell: ({row: {id}}) => {
                return (
                    <Box>
                        <RouteLink to={"/options/edit/" + id}>
                            <EditOutlinedIcon sx={{color: colors.greenAccent[500]}}/>
                        </RouteLink>
                    </Box>
                );
            },
        },
        {
            field: "userId",
            flex: 1,
            headerName: "User Id",
            type: "string",
        },
        {field: "metal", headerName: "Metal"},
        {
            field: "optionType",
            headerName: "Option Type",
            flex: 1,
            renderCell: (params) => (
                <Typography color={params.row.optionType === "PUT" ? colors.greenAccent[500] : colors.redAccent[500]}
                            fontWeight="700">
                    {params.row.optionType}
                </Typography>
            ),
        },
        {field: "tradeType", headerName: "Trade Type", flex: 1},
        {
            field: "oz",
            flex: 1,
            headerName: "Oz",
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "price",
            flex: 1,
            headerName: "Price",
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "effectiveStrike",
            flex: 1,
            headerName: "Effective Strike",
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "primeProOz",
            flex: 1,
            headerName: "Prime pro Oz",
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "date",
            flex: 1,
            headerName: "Date",
            type: "date",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "partner",
            flex: 1,
            headerName: "Partner",
        },
        {
            field: "status",
            flex: 1,
            headerName: "Status",
        },
    ];

    return (
        <Box m="20px">
            <Header
                title="Closed Options"
                subtitle="List of closed Options"
            />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        textOverflow: "clip",
                        whiteSpace: "break-spaces",
                        lineHeight: 1
                    },
                }}
            >
                <DataGrid
                    checkboxSelection
                    rows={tableData}
                    columns={columns}
                    components={{Toolbar: GridToolbar}}
                />
            </Box>
        </Box>
    );
};

export default OptionsClosed;
