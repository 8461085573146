import React, {useState, useEffect} from 'react'
import {Link as RouteLink} from "react-router-dom";
import {Box, Typography, useTheme} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import {useAuth} from "react-oidc-context";
import axios from "axios";

const UsersList = () => {
    const [tableData, setTableData] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const url = apiEndpoint + "/users";
    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        axios.get(url, authConfig)
            .then((response) => {
                console.log(response);
                setTableData(response.data);
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [])
    console.log(tableData)

    const columns = [
        {
            field: "action",
            headerName: "",
            flex: 0.4,
            renderCell: ({row: {id}}) => {
                return (
                    <Box>
                        <RouteLink to={"/users/edit/" + id}>
                            <EditOutlinedIcon sx={{color: colors.greenAccent[500]}}/>
                        </RouteLink>
                    </Box>
                );
            },
        },
        {
            field: "uid",
            headerName: "UID",
            flex: 1
        },
        {
            field: "firstName",
            headerName: "First Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "lastName",
            headerName: "Last Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "age",
            headerName: "Age",
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "phone",
            headerName: "Phone Number",
            flex: 1,
        },
        {
            field: "city",
            headerName: "City",
            flex: 1,
        },
        {
            field: "address",
            headerName: "Address",
            flex: 1,
        },
        {
            field: "accessLevel",
            headerName: "Access Level",
            flex: 1,
            renderCell: ({row: {accessLevel}}) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        color={colors.primary[100]}
                        backgroundColor={
                            accessLevel === "admin"
                                ? colors.greenAccent[600]
                                : accessLevel === "manager"
                                ? colors.greenAccent[700]
                                : colors.greenAccent[700]
                        }
                        borderRadius="4px"
                    >
                        {accessLevel === "admin" && <AdminPanelSettingsOutlinedIcon/>}
                        {accessLevel === "manager" && <SecurityOutlinedIcon/>}
                        {accessLevel === "user" && <LockOpenOutlinedIcon/>}
                        <Typography color={colors.primary[400]} sx={{ml: "5px", color: colors.primary[100]}}>
                            {accessLevel}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    return (
        <Box m="20px">
            <Header title="Users" subtitle="Managing the User Members"/>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                <DataGrid checkboxSelection rows={tableData} columns={columns}/>
            </Box>
        </Box>
    );
};

export default UsersList;
