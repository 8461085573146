import {Box, Button, Typography, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import MetalsLineChartComponent from "../../components/MetalsLineChartComponent";
import React, {useEffect, useState, useContext} from "react";
import {NumericFormat} from "react-number-format";
import CurrencyContext from "../../contexts/CurrencyContext";
import axios from "axios";
import {useAuth} from "react-oidc-context";
import MetalsCandlestickChartComponent from "../../components/MetalsCandlestickChartComponent";
import {useTranslation} from "react-i18next";

const CurrencyLineChart = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [currencyValueContext] = useContext(CurrencyContext);
    const currencySymbol = currencyValueContext === "EUR" ? "€" : "$";
    const [period, setPeriod] = useState(30);

    const [rateData, setRateData] = useState([]);
    const [chartType, setChartType] = useState("Candlestick");


    const url = process.env.REACT_APP_API_ENDPOINT + "/rate/latest?currency=" + currencyValueContext + "&type=line";
    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        axios.get(url, authConfig)
        .then((response) => {
            console.log(response);
            setRateData(response.data);
        }, (error) => {
            console.log(error);
            if (error.response.status === 401) {
                auth.signinRedirect();
            }
        });
    }, [url])

    const handleChangeChartType = (chartType) => {
        setChartType(chartType);
        console.log("chartType " + chartType);
    };

    const handleChangePeriod = (period) => {
        setPeriod(period);
    };

    return (
    <Box m="20px">
        <Header title={t("pages.currencyLineChart.title.short") + " (" + period + " " + t("chart.days") + ")"}
                subtitle={t("pages.currencyLineChart.title.long")}
        />
        {/* GRID & CHARTS */}
        <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            // gridAutoRows="140px"
            gap="20px"
        >
            {/* ROW 1 */}
           <Box
                gridColumn="span 12"
                // backgroundColor={colors.primary[400]}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Box width="100%">
                    <Box display="flex" justifyContent="space-between" mt="20px">
                        <Box display="flex" justifyContent="space-between" mt="20px">
                            <Box>
                                <Button type="button" color="button" variant="contained" onClick={() => handleChangePeriod(30)}
                                        sx={{marginRight: "20px"}} disabled={30 === period}>
                                    30 {t("chart.days")}
                                </Button>
                                <Button type="button" color="button" variant="contained" onClick={() =>handleChangePeriod(60)}
                                        sx={{marginRight: "20px"}} disabled={60 === period}>
                                    60 {t("chart.days")}
                                </Button>
                                <Button type="button" color="button" variant="contained" onClick={() => handleChangePeriod(90)}
                                        sx={{marginRight: "20px"}} disabled={90 === period}>
                                    90 {t("chart.days")}
                                </Button>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mt="20px">
                            <Box>
                                <Button type="button" color="button" variant="contained" onClick={() => handleChangeChartType("Candlestick")}
                                        sx={{marginRight: "20px"}} disabled={"Candlestick" === chartType}>
                                    {t("chart.candlestick")}
                                </Button>
                                <Button type="button" color="button" variant="contained" onClick={() => handleChangeChartType("Line")}
                                        sx={{marginRight: "20px"}} disabled={"Line" === chartType}>
                                    {t("chart.line")}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mt="40px"
                    >
                        <Typography
                            variant="h3"
                            fontWeight="bold"
                            color={colors.greenAccent[500]}
                        >
                            {currencyValueContext === "EUR" ? "USD" : "EUR"} <NumericFormat value={currencyValueContext === "EUR" ? rateData.usd : rateData.eur}  suffix={' ' + currencySymbol + ' '} thousandSeparator=" " decimalSeparator="," decimalScale={2} displayType="text" />
                        </Typography>
                    </Box>
                    <Box
                        // m="10px 0 0 0"
                        height="50vh"
                        width="100%"
                        mt="-40px"
                    >
                        {chartType === "Candlestick" ?
                            <MetalsCandlestickChartComponent metal={currencyValueContext === "EUR" ? "USD" : "EUR"} currency={currencyValueContext} period={period}/> :
                            <MetalsLineChartComponent metal={currencyValueContext === "EUR" ? "USD" : "EUR"} currency={currencyValueContext} period={period}/>
                        }
                    </Box>
                </Box>
            </Box>

        </Box>
    </Box>
  )};

export default CurrencyLineChart;
