import {useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, TextField, useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from '@mui/material/MenuItem';
import {Formik} from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import {tokens} from "../../theme";
import {useAuth} from "react-oidc-context";
import axios from "axios";

const OptionsEditOrCreate = () => {
    const [formData, setFormData] = useState(null);
    const [formSaved, setFormSaved] = useState(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const routeParams = useParams();
    const id = routeParams.id;
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const urlId = apiEndpoint + "/options/" + id;
    const url = apiEndpoint + "/options";
    const navigate = useNavigate();
    console.log("option id: " + id);

    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        if (id) {
            axios.get(urlId, authConfig)
                .then((response) => {
                    console.log(response);
                    setFormData(response.data);
                }, (error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        auth.signinRedirect();
                    }
                });
        } else {
            setFormData(null);
        }
    }, [id, urlId])
    console.log(formData)

    const isNonMobile = useMediaQuery("(min-width:600px)");

    const optionTypes = [
        {
            value: 'SELL',
            label: 'SELL',
        },
        {
            value: 'BUY',
            label: 'BUY',
        }
    ];

    const tradeTypes = [
        {
            value: 'CALL',
            label: 'CALL',
        },
        {
            value: 'PUT',
            label: 'PUT',
        }
    ];

    const metals = [
        {
            value: 'AU',
            label: 'AU',
        },
        {
            value: 'AG',
            label: 'AG',
        },
        {
            value: 'PT',
            label: 'PT',
        },
    ];

    const statuses = [
        {
            value: 'POSSIBLE',
            label: 'POSSIBLE',
        },
        {
            value: 'ACTIVE',
            label: 'ACTIVE',
        },
        {
            value: 'FINISHED',
            label: 'FINISHED',
        },
        {
            value: 'EXPIRED',
            label: 'EXPIRED',
        },
        {
            value: 'CLOSED',
            label: 'CLOSED',
        },
        {
            value: 'ARCHIVE',
            label: 'ARCHIVE',
        }
    ];

    function sendAjax(url, values, method) {
        const authToken = auth.user.access_token;
        axios({
            method: method,
            url: url,
            data: values,
            headers:  {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authToken
            }
        }).then((response) => {
            console.log(response);
            setFormData(response.data);
            setFormSaved(true);
            console.log(formSaved);
        }, (error) => {
            console.log(error);
            setFormSaved(false);
            console.error('There was an error!', error);
            if (error.response.status === 401) {
                auth.signinRedirect();
            }
        });
    }

    const handleFormReset = () => {
        setFormData(null);
    };

    const handleFormDelete = (id) => {
        sendAjax(urlId, formData, "DELETE");
        setFormData(null);
        navigate(-1, {replace: true});
    };


    return (
        <Box m="20px">
            {id ? (<Header title="Edit Option" subtitle="Edit Option"/>) :
                (<Header title="Create Option" subtitle="Create New Option"/>)
            }
            <Box
                sx={
                    {
                        "& input[type='date']::-webkit-calendar-picker-indicator": {
                            filter: 'invert(0.5)',
                        },
                        "& input[type='time']::-webkit-calendar-picker-indicator": {
                            filter: 'invert(0.5)',
                        },
                        "& .Mui-focused": {
                            color: colors.greenAccent[300],
                        },
                        "& label.Mui-focused": {
                            color: colors.greenAccent[300],
                        },
                    }}
            >

                <Formik
                    onSubmit={(values, event) => {
                        if (id) {
                            sendAjax(urlId, values, "PUT");
                        } else {
                            sendAjax(url, values, "POST");
                        }
                    }}
                    enableReinitialize
                    initialValues={formData || initialValues}
                    validationSchema={checkoutSchema}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          isSubmitting,
                          handleSubmit,
                          dirty,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                                }}
                            >
                                <TextField
                                    id="filled-select-metal"
                                    select
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Metal"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.metal || ""}
                                    name="metal"
                                    error={!!touched.metal && !!errors.metal}
                                    helperText={touched.metal && errors.metal}
                                    sx={{gridColumn: "span 1"}}
                                >
                                    {metals.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="filled-select-option-type"
                                    select
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Option Type"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.optionType || ""}
                                    name="optionType"
                                    error={!!touched.optionType && !!errors.optionType}
                                    helperText={touched.optionType && errors.optionType}
                                    sx={{gridColumn: "span 1"}}
                                >
                                    {optionTypes.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="filled-select-trade-type"
                                    select
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Trade Type"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.tradeType || ""}
                                    name="tradeType"
                                    error={!!touched.tradeType && !!errors.tradeType}
                                    helperText={touched.tradeType && errors.tradeType}
                                    sx={{gridColumn: "span 1"}}
                                >
                                    {tradeTypes.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="filled-select-status"
                                    select
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Status"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.status || ""}
                                    name="status"
                                    error={!!touched.status && !!errors.status}
                                    helperText={touched.status && errors.status}
                                    sx={{gridColumn: "span 1"}}
                                >
                                    {statuses.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Price"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.price || ""}
                                    name="price"
                                    error={!!touched.price && !!errors.price}
                                    helperText={touched.price && errors.price}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Strike"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.strike || ""}
                                    name="strike"
                                    error={!!touched.strike && !!errors.strike}
                                    helperText={touched.strike && errors.strike}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Effective Strike"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.effectiveStrike || ""}
                                    name="effectiveStrike"
                                    error={!!touched.effectiveStrike && !!errors.effectiveStrike}
                                    helperText={touched.effectiveStrike && errors.effectiveStrike}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Curs"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.curs || ""}
                                    name="curs"
                                    error={!!touched.curs && !!errors.curs}
                                    helperText={touched.curs && errors.curs}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Oz"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.oz || ""}
                                    name="oz"
                                    error={!!touched.oz && !!errors.oz}
                                    helperText={touched.oz && errors.oz}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Prime pro Oz"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.primeProOz || ""}
                                    name="primeProOz"
                                    error={!!touched.primeProOz && !!errors.primeProOz}
                                    helperText={touched.primeProOz && errors.primeProOz}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <Box sx={{gridColumn: "span 1"}}/>
                                <Box sx={{gridColumn: "span 1"}}/>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    format="dd.MM.yyyy"
                                    type="date"
                                    label="Date"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.date || ""}
                                    name="date"
                                    error={!!touched.date && !!errors.date}
                                    helperText={touched.date && errors.date}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="time"
                                    label="Time"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.time || ""}
                                    name="time"
                                    error={!!touched.time && !!errors.time}
                                    helperText={touched.time && errors.time}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Days"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.days || ""}
                                    name="days"
                                    error={!!touched.days && !!errors.days}
                                    helperText={touched.days && errors.days}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <Box sx={{gridColumn: "span 1"}}/>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    format="dd.MM.yyyy"
                                    type="date"
                                    label="Payment Date"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.paymentDate || ""}
                                    name="paymentDate"
                                    error={!!touched.paymentDate && !!errors.paymentDate}
                                    helperText={touched.paymentDate && errors.paymentDate}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    format="dd.MM.yyyy"
                                    type="date"
                                    label="End Date"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.endDate || ""}
                                    name="endDate"
                                    error={!!touched.endDate && !!errors.endDate}
                                    helperText={touched.endDate && errors.endDate}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    format="dd.MM.yyyy"
                                    type="date"
                                    label="Expired Date"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.expiredDate || ""}
                                    name="expiredDate"
                                    error={!!touched.expiredDate && !!errors.expiredDate}
                                    helperText={touched.expiredDate && errors.expiredDate}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <Box sx={{gridColumn: "span 1"}}/>

                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Partner"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.partner || ""}
                                    name="partner"
                                    error={!!touched.partner && !!errors.partner}
                                    helperText={touched.partner && errors.partner}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Strategy"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.strategy || ""}
                                    name="strategy"
                                    error={!!touched.strategy && !!errors.strategy}
                                    helperText={touched.strategy && errors.strategy}
                                    sx={{gridColumn: "span 1"}}
                                />

                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    disabled
                                    label="Id"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.id || ""}
                                    name="id"
                                    error={!!touched.id && !!errors.id}
                                    helperText={touched.id && errors.id}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <Box sx={{gridColumn: "span 1"}}/>
                            </Box>
                            <Box display="flex" justifyContent="space-between" mt="20px">
                                <Box>
                                    <Button type="submit" color="button" variant="contained" disabled={!dirty}>
                                        {id ? "Save Option" : "Create New Option"}
                                    </Button>
                                </Box>
                                <Box>
                                    <Button type="reset" color="caution" variant="contained" onClick={handleFormReset}
                                            sx={{marginRight: "20px"}}>
                                        Reset
                                    </Button>
                                    <Button type="button" color="caution" variant="contained"
                                            onClick={() => handleFormDelete(id)} disabled={!id}>
                                        Delete
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    status: yup.string().required("required"),
    metal: yup.string().required("required"),
    oz: yup.number().required("required"),
    // primeProOz: yup.number().required("required"),
    // price: yup.number().required("required"),
    // curs: yup.number().required("required"),
    strike: yup.number().required("required"),
    // effectiveStrike: yup.number().required("required"),
    // date: yup.date().required("required"),
    // time: yup.string().required("required"),
});

const initialValues = {
    metal: "GOLD",
    optionType: "CALL",
    tradeType: "BUY",
    status: "POSSIBLE",

};

export default OptionsEditOrCreate;
