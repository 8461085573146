import {useEffect, useState} from "react";
import {useTheme} from "@mui/material";
import {ResponsivePie} from "@nivo/pie";
import {tokens} from "../theme";
import {useAuth} from "react-oidc-context";
import axios from "axios";
import {useTranslation} from "react-i18next";

const MetalsPieChartComponent = ({isDashboard = false}) => {
    const { t } = useTranslation();
    const [tableData, setTableData] = useState([]);

    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const urlQuantity = apiEndpoint + "/metals/quantity/all";
    const urlBalance = apiEndpoint + "/metals/balance/all?currency=EUR";

    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        let url = isDashboard ? urlBalance : urlQuantity;
        axios.get(url, authConfig)
            .then((response) => {
                console.log(response);
                const data = response.data;
                let result = [];
                for (var key in data) {
                    let val = data[key];
                    let res = {};
                    res['id'] = t(key.toLowerCase());
                    res['label'] = t(key.toLowerCase());
                    // res['label'] = t("gold");
                    res['value'] = val;
                    result.push(res);
                    console.log("val: " + val);
                }
                setTableData(result)
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [])
    console.log(tableData)

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <ResponsivePie
            data={tableData}
            theme={{
                "fontSize": 16,
                // "fontWeight" : "bold",
                axis: {
                    domain: {
                        line: {
                            stroke: colors.grey[100],
                        },
                    },
                    legend: {
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                    ticks: {
                        line: {
                            stroke: colors.grey[100],
                            strokeWidth: 1,
                        },
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                },
                legends: {
                    text: {
                        fill: colors.grey[100],
                    },
                },
            }}
            margin={{top: 40, right: 80, bottom: isDashboard ? 20 : 80, left: 80}}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor={colors.grey[100]}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{from: "color"}}
            enableArcLabels={false}
            arcLabelsRadiusOffset={0.4}
            arcLabelsSkipAngle={7}
            arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
            }}
            defs={[
                {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    size: 4,
                    padding: 1,
                    stagger: true,
                },
                {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                },
            ]}
            legends={isDashboard ? [] : [
                {
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 140,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    itemDirection: "left-to-right",
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: "circle",
                    effects: [],
                },
            ]}
        />
    );
};

export default MetalsPieChartComponent;
