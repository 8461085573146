import {Box, useTheme} from "@mui/material";
import {tokens} from "../theme";
import {useEffect, useState} from "react";
import axios from "axios";
import {useAuth} from "react-oidc-context";
import Chart from "react-apexcharts";
import {useTranslation} from "react-i18next";

const MetalsCandlestickChartComponent = ({isCustomLineColors = false, isDashboard = false, metal = "Gold", currency = "EUR", period = 30}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [chartData, setChartData] = useState([]);

    const url = process.env.REACT_APP_API_ENDPOINT + "/rate/latest/" + metal + "/" + period + "?currency=" + currency + "&type=candle";
    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        axios.get(url, authConfig)
            .then((response) => {
                const data = response.data;
                let series = [];
                series.push(data[0]);
                setChartData(series);
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [url, metal])

    let verticalTitle = t("chart.pricePerOz") + " " + currency;
    console.log(metal);
    if (metal === "EUR" || metal === "USD") {
        verticalTitle = metal + " " + t("chart.rateIn") + " " + currency;
    }

    const candlestickchartData = {
        series: [{ data: [] }],
        options: {
            chart: {
                type: 'candlestick',
                height: 'auto'
            },
            yaxis: {
                show: true,
                showAlways: true,
                labels: {
                    show: true,
                    align: 'right',
                    style: {
                        colors: [colors.grey[100]],
                        fontSize: '12px',
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    formatter: (value) => { return Math.round(value) },
                },
                title: {
                    text: verticalTitle,
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: colors.grey[100],
                        fontSize: '12px',
                    },
                },
            },
            tooltip: {
                style: {
                    fontSize: '22px',
                    fontFamily: undefined
                },
            },
            xaxis: {
                position: 'bottom',
                labels: {
                    show: true,
                    rotate: -45,
                    rotateAlways: true,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    trim: false,
                    minHeight: undefined,
                    maxHeight: 120,
                    style: {
                        colors: colors.grey[100],
                        fontSize: '12px',
                    },
                    offsetX: 0,
                    offsetY: 0,
                },
                tooltip: {
                    enabled: true,
                    style: {
                        fontSize: '20px',
                    },
                },
            },
       },
    };


    return (
        <Box
            m="40px 30px 0 0"
            height="75vh"
            sx={{
                ".apexcharts-tooltip": {
                    background: `${colors.primary[400]} !important`,
                    color: `${colors.grey[100]} !important`,
                    padding: '5px',
                    fontSize: '20px'
                },
                ".apexcharts-xaxistooltip": {
                    background: `${colors.primary[400]} !important`,
                    color: `${colors.grey[100]} !important`,
                    padding: '5px',
                },
            }}
        >
        <Chart options={candlestickchartData.options} series={chartData} type="candlestick" height='450' />
        </Box>
    );
};

export default MetalsCandlestickChartComponent;
