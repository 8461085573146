import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import React, {useEffect, useState} from "react";
import {Link as RouteLink} from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";

const ApplicationSettings = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [tableData, setTableData] = useState([]);


    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const url = apiEndpoint + "/settings/application";
    useEffect(() => {
        fetch(url)
            .then((data) => data.json())
            .then((data) => setTableData(data))
    }, [])
    console.log(tableData)


    const columns = [
        {
            field: "action",
            headerName: "",
            flex: 0.2,
            renderCell: ({row: {key}}) => {
                return (
                    <Box>
                        <RouteLink to={"/settings/application/edit/" + key}>
                            <EditOutlinedIcon sx={{color: colors.greenAccent[500]}}/>
                        </RouteLink>
                    </Box>
                );
            },
        },
        {
            field: "key",
            headerName: "Key",
            flex: 1,
            cellClassName: "key-column--cell",
        },
        {
            field: "value",
            headerName: "Value",
            flex: 3,
            cellClassName: "value-column--cell",
        },
    ];

    return (
        <Box m="20px">
            <Header title="Application Settings" subtitle="List of Application Settings" />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    getRowId={(row) => row.key}
                    checkboxSelection
                    rows={tableData}
                    columns={columns}
                    components={{Toolbar: GridToolbar}}
                />
            </Box>
        </Box>
    );
};

export default ApplicationSettings;
