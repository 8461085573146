import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import {useAuth} from "react-oidc-context";
import { useTranslation } from 'react-i18next'
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import EuroSymbolRoundedIcon from '@mui/icons-material/EuroSymbolRounded';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import MovingOutlinedIcon from '@mui/icons-material/MovingOutlined';
import ShopOutlinedIcon from '@mui/icons-material/ShopOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';


const Item = ({ title, to, icon, selected, setSelected, variant = "h5" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography variant={variant}>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {

  const auth = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  console.log(JSON.stringify(auth.user));
  console.log(auth.user?.profile?.email);

  const { t } = useTranslation();


  if (! auth.isAuthenticated) {
    return (
        <div>
        </div>
    );
  } else
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  {t("sidePanel.adminSection.title")}
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/no-photo.jpeg`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {auth?.user?.profile?.name}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {t("sidePanel.adminSection.adminRole")}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title={t("sidePanel.menuItem.dashboard")}
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              variant="h3"
            />

            {!isCollapsed && (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
              <Typography
                  variant="h4"
                  color={colors.grey[200]}
                  fontWeight="700"
                  sx={{ m: "25px 0 15px 0" }}
              >
                {t("sidePanel.menuItem.options")}
              </Typography>
            </Box>
            )}

            {/*import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';*/}
            {/*import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';*/}
            {/*import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';*/}
            {/*import LineAxisOutlinedIcon from '@mui/icons-material/LineAxisOutlined';*/}
            {/*import ListOutlinedIcon from '@mui/icons-material/ListOutlined';*/}
            {/*import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';*/}
            {/*import MovingOutlinedIcon from '@mui/icons-material/MovingOutlined';*/}
            {/*import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';*/}
            <Item
              title={t("sidePanel.submenuItem.options.statusQuo")}
              to="/options/statusquo"
              icon={<ListAltOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.options.all")}
                to="/options/all"
                icon={<ListAltOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.options.active")}
              to="/options/active"
              icon={<MovingOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.options.possible")}
              to="/options/possible"
              icon={<InsightsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.options.finished")}
              to="/options/finished"
              icon={<PlaylistAddCheckOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.options.closed")}
              to="/options/closed"
              icon={<PlaylistAddCheckOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.options.expired")}
              to="/options/expired"
              icon={<PlaylistAddCheckOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.options.archive")}
              to="/options/archive"
              icon={<ArchiveOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.options.new")}
                to="/options/create"
                icon={<LibraryAddOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
            />


            {!isCollapsed && (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
              <Typography
                  variant="h4"
                  color={colors.grey[200]}
                  fontWeight="700"
                  sx={{ m: "25px 0 15px 0" }}
              >
                {t("sidePanel.menuItem.trade")}
              </Typography>
            </Box>
            )}

            <Item
              title={t("sidePanel.submenuItem.trade.tradeInformation")}
              to="/trades/active"
              icon={<EuroSymbolRoundedIcon />}
              selected={selected}
              setSelected={setSelected}
            />


            {!isCollapsed && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  <Typography
                      variant="h4"
                      color={colors.grey[200]}
                      fontWeight="700"
                      sx={{ m: "25px 0 15px 0" }}
                  >
                    {t("sidePanel.menuItem.charts")}
                  </Typography>
                </Box>
            )}

            <Item
                title={t("sidePanel.submenuItem.charts.metalsChart")}
                to="/charts/metalsLineChart"
                icon={<TimelineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.charts.currencyChart")}
                to="/charts/currencyLineChart"
                icon={<TimelineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.charts.metalsPieChart")}
                to="/charts/metalsPieChart"
                icon={<PieChartOutlineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
            />


            {!isCollapsed && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                  <Typography
                      variant="h4"
                      color={colors.grey[200]}
                      fontWeight="700"
                      sx={{ m: "25px 0 15px 0" }}
                  >
                    {t("sidePanel.menuItem.metals")}
                  </Typography>
                </Box>
            )}

            <Item
                title={t("sidePanel.submenuItem.metals.metalsBalances")}
              to="/metals/balances"
              icon={<ShopOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />


            {!isCollapsed && (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
              <Typography
                  variant="h4"
                  color={colors.grey[200]}
                  fontWeight="700"
                  sx={{ m: "25px 0 15px 0" }}
              >
                {t("sidePanel.menuItem.users")}
              </Typography>
            </Box>
            )}


            <Item
                title={t("sidePanel.submenuItem.users.manageUsers")}
              to="/users/list"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.users.contacts")}
              to="/users/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.users.newUser")}
                to="/users/create"
                icon={<PersonOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
            />
            {!isCollapsed && (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
              <Typography
                  variant="h4"
                  color={colors.grey[200]}
                  fontWeight="700"
                  sx={{ m: "25px 0 15px 0" }}
              >
                {t("sidePanel.menuItem.pages")}
              </Typography>
            </Box>
            )}


            <Item
                title={t("sidePanel.submenuItem.pages.calendar")}
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.pages.messages")}
              to="/messages"
              icon={<MessageOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
                title={t("sidePanel.submenuItem.pages.help")}
              to="/help"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
