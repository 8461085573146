import {useState} from "react";
import {Routes, Route} from "react-router-dom";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {ColorModeContext, useMode} from "./theme";

import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard/Dashboard";
import UsersList from "./scenes/users/UsersList";
import UsersContacts from "./scenes/users/UsersContacts";
import UsersEditOrCreate from "./scenes/users/UsersEditOrCreate";
import MetalsLineChart from "./scenes/charts/MetalsLineChart";
import MetalsPieChart from "./scenes/charts/MetalsPieChart";
import Help from "./scenes/help/Help";
import OptionsAll from "./scenes/options/OptionsAll";
import OptionsActive from "./scenes/options/OptionsActive";
import OptionsPossible from "./scenes/options/OptionsPossible";
import OptionsFinished from "./scenes/options/OptionsFinished";
import OptionsClosed from "./scenes/options/OptionsClosed";
import OptionsExpired from "./scenes/options/OptionsExpired";
import OptionsArchive from "./scenes/options/OptionsArchive";
import OptionsEditOrCreate from "./scenes/options/OptionsEditOrCreate";
import TradesActive from "./scenes/trades/TradesActive";
import MetalsBalances from "./scenes/metals/MetalsBalances";
import MetalsEditOrCreate from "./scenes/metals/MetalsEditOrCreate";
import Calendar from "./scenes/calendar/Calendar";
import Messages from "./scenes/messages/Messages";
import NewMessage from "./scenes/messages/NewMessage";
import Notifications from "./scenes/messages/Notifications";
import ApplicationSettings from "./scenes/settings/ApplicationSettings";
import ServerSettings from "./scenes/settings/ServerSettings";
import ServerInit from "./scenes/settings/ServerInit";
import CurrencyContext from "./contexts/CurrencyContext";
import CurrencyLineChart from "./scenes/charts/CurrencyLineChart";
import Login from "./security/Login";
import OutletProtectedRoute from "./security/OutletProtectedRoute";
import UserInfo from "./scenes/users/UserInfo";
import OptionsStatusQuo from "./scenes/options/OptionsStatusQuo";
import DashboardStatusQuo from "./scenes/dashboard/DashboardStatusQuo";


function App() {
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    const [currencyValueContext, setCurrencyValueContext] = useState("EUR");

    return (
        <CurrencyContext.Provider value={[currencyValueContext, setCurrencyValueContext]}>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <div className="app">
                        <Sidebar isSidebar={isSidebar}/>
                        <main className="content">
                            <Topbar setIsSidebar={setIsSidebar}/>
                            <Routes>
                                <Route path="/login" element={<Login/>}/>
                                <Route element={<OutletProtectedRoute />}>
                                    <Route path="/" element={<Dashboard/>}/>
                                    <Route path="/dashboardStatus" element={<DashboardStatusQuo/>}/>
                                    <Route path="/users/list" element={<UsersList/>}/>
                                    <Route path="/users/contacts" element={<UsersContacts/>}/>
                                    <Route path="/users/edit/:id" element={<UsersEditOrCreate/>}/>
                                    <Route path="/users/create" element={<UsersEditOrCreate/>}/>
                                    <Route path="/users/info" element={<UserInfo/>}/>
                                    <Route path="/charts/metalsLineChart" element={<MetalsLineChart/>}/>
                                    <Route path="/charts/currencyLineChart" element={<CurrencyLineChart/>}/>
                                    <Route path="/charts/metalsPieChart" element={<MetalsPieChart/>}/>
                                    <Route path="/help" element={<Help/>}/>
                                    <Route path="/calendar" element={<Calendar/>}/>
                                    <Route path="/options/statusquo" element={<OptionsStatusQuo/>}/>
                                    <Route path="/options/all" element={<OptionsAll/>}/>
                                    <Route path="/options/edit/:id" element={<OptionsEditOrCreate/>}/>
                                    <Route path="/options/create" element={<OptionsEditOrCreate/>}/>
                                    <Route path="/options/active" element={<OptionsActive/>}/>
                                    <Route path="/options/possible" element={<OptionsPossible/>}/>
                                    <Route path="/options/finished" element={<OptionsFinished/>}/>
                                    <Route path="/options/closed" element={<OptionsClosed/>}/>
                                    <Route path="/options/expired" element={<OptionsExpired/>}/>
                                    <Route path="/options/archive" element={<OptionsArchive/>}/>
                                    <Route path="/metals/balances" element={<MetalsBalances/>}/>
                                    <Route path="/metals/edit/:id" element={<MetalsEditOrCreate/>}/>
                                    <Route path="/trades/active" element={<TradesActive/>}/>
                                    <Route path="/notifications" element={<Notifications/>}/>
                                    <Route path="/settings/application" element={<ApplicationSettings/>}/>
                                    <Route path="/settings/server" element={<ServerSettings/>}/>
                                    <Route path="/settings/init" element={<ServerInit/>}/>
                                    <Route path="/messages" element={<Messages/>}/>
                                    <Route path="/messages/new" element={<NewMessage/>}/>
                                </Route>
                            </Routes>
                        </main>
                    </div>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </CurrencyContext.Provider>
    );
}

export default App;
