import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";

const Help = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="Help" subtitle="Help Page" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What Are Options?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>

            Options are tradable contracts that investors use to speculate about whether an asset’s price will be higher or lower at a certain date in the future, without any requirement to actually buy the asset in question.

            Nifty 50 options, for example, allow traders to speculate as to the future direction of this benchmark stock index, which is commonly understood as a stand-in for the entire Indian stock market.

            At first glance, options seem a little counterintuitive, but they’re not as complicated as they appear. To understand options, you just need to know a few key terms:

            Derivative. Options are what’s known as a derivative, meaning that they derive their value from another asset. Take stock options, where the price of a given stock dictates the value of the option contract.
            Call option and put option. A call option gives you the opportunity to buy a security at a predetermined price by a specified date while a put option allows you to sell a security at a future date and price.
            Strike price and expiration date. That predetermined price mentioned above is what’s known as a strike price. Traders have until an option contract’s expiration date to exercise the option at its strike price.
            Premium. The price to purchase an option is called a premium, and it’s calculated based on the underlying security’s price and values.
            Intrinsic value and extrinsic value. Intrinsic value is the difference between an option contract’s strike price and current price of the underlying asset. Extrinsic value represents other factors outside of those considered in intrinsic value that affect the premium, like how long the option is good for.
            In-the-money and out-of-the-money. Depending on the underlying security’s price and the time remaining until expiration, an option is said to be in-the-money (profitable) or out-of-the-money (unprofitable).

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How Options Pricing Works
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Let’s make sense of all of this terminology with an example. Consider a stock that’s currently trading for INR 100 a share. Here’s how the premiums—or the prices—function for different options based on the strike price.

            Call Option Premium	Strike Price	Put Option Premium
            Highest	INR 90	Lowest
            INR 95
            ↕	INR 100 —Current Price	↕
            INR 105
            Lowest	INR 110	Highest
            When trading options, you pay a premium up front, which then gives you the option to buy this hypothetical stock—call options—or sell the stock—put options—at the designated strike price by the expiration date.

            A lower strike price has more intrinsic value for call options since the options contract lets you buy the stock at a lower price than what it’s trading for right now. If the stock’s price remains INR 100, your call options are in-the-money, and you can buy the stock at a discount.

            Conversely, a higher strike price has more intrinsic value for put options because the contract allows you to sell the stock at a higher price than where it’s trading currently. Your options are in-the-money if the stock stays at INR 100, but you have the right to sell it at a higher strike price, say INR 110.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How Options Trading Works
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can deploy a range of options trading strategies, from a straightforward approach to intricate, complicated trades. But broadly speaking, trading call options is how you wager on rising prices while trading put options is a way to bet on falling prices.

            Options contracts give investors the right to buy or sell a minimum of 100 shares of stock or other assets. However, there’s no obligation to exercise options in the event a trade isn’t profitable. Deciding not to exercise options means the only money an investor stands to lose is the premium paid for the contracts. As a result, options trading can be a relatively low-cost way to speculate on a whole range of asset classes.

            Option trading allows you to speculate on:

            Whether an asset’s price will rise or fall from its current price.
            By how much an asset’s price will rise or fall.
            By what date these price changes will occur.
            With call and put options, you need the underlying asset’s price to rise or fall to break even, which is a rupee amount equal to the premium paid plus the strike price. Here’s how you earn a profit:

            Call options. Once the underlying asset’s price has exceeded the break-even price, you can sell the call option—called closing your position—and earn the difference between the premium you paid and the current premium. Alternatively, you can exercise the option to buy the underlying asset at the agreed-upon strike price.
            Put options. Once the asset’s price has fallen below the break-even level, you can sell the options contract—closing your position—and collect the difference between the premium you paid and the current premium. Alternatively, you can exercise the option to sell the underlying asset at the agreed-upon strike price.
            If the asset’s price moves in the opposite direction than desired for either a call or put option, you simply let the contract expire—and your losses are equal to the amount you paid for the option (e.g., the premium plus associated trading fees).

            Options trading strategies can become very complicated when advanced traders pair two or more calls or puts with different strike prices or expiration dates.
          </Typography>
        </AccordionDetails>
      </Accordion>
       </Box>
  );
};

export default Help;
