import React, {useEffect, useState} from "react";
import {Link as RouteLink} from "react-router-dom";
import {Box, useTheme, Typography} from "@mui/material";
import Modal from '@mui/material/Modal';
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {tokens} from "../../theme";
import Header from "../../components/Header";
import {useAuth} from "react-oidc-context";
import axios from "axios";
import {useTranslation} from "react-i18next";

const OptionsAll = () => {
    const { t } = useTranslation();
    const [tableData, setTableData] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const url = apiEndpoint + "/options";
    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        axios.get(url, authConfig)
            .then((response) => {
                console.log(response);
                setTableData(response.data);
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [])
    console.log(tableData)

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #777',
        boxShadow: 24,
        p: 4,
    };

    const columns = [
        {
            field: "action",
            headerName: "",
            flex: 0.4,
            renderCell: ({row: {id}}) => {
                return (
                    <Box>
                        <RouteLink to={"/options/edit/" + id}>
                            <EditOutlinedIcon sx={{color: colors.greenAccent[500]}}/>
                        </RouteLink>
                    </Box>
                );
            },
        },
        {field: "metal", headerName: t("table.metal"), flex: 1},
        // {
        //     field: "optionType",
        //     flex: 1,
        //     headerName: "Option Type",
        //     renderCell: (params) => (
        //         <Typography color={params.row.optionType === "PUT" ? colors.greenAccent[500] : colors.redAccent[500]}
        //                     fontWeight="700">
        //             {params.row.optionType}
        //         </Typography>
        //     ),
        // },
        {
            field: "optionType",
            flex: 1,
            headerName: t("table.optionType"),
            renderCell: (params) => (
                <Typography color={params.row.optionType === "PUT" ? colors.greenAccent[500] : colors.redAccent[500]}
                            fontWeight="700">
                    {params.row.optionType}
                </Typography>
            ),
        },        {
            field: "oz",
            flex: 1,
            headerName: t("table.oz"),
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "price",
            flex: 1,
            headerName: t("table.price"),
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "strike",
            flex: 1,
            headerName: t("table.strike"),
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "days",
            flex: 1,
            headerName: t("table.days"),
            type: "number",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "endDate",
            flex: 1,
            headerName: t("table.endDate"),
            type: "date",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "status",
            flex: 1,
            headerName: t("table.status"),
        },
    ];


    return (
        <Box m="20px">
            <Header
                title={t("pages.optionsAll.title.short")}
                subtitle={t("pages.optionsAll.title.long")}
            />

            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        textOverflow: "clip",
                        whiteSpace: "break-spaces",
                        lineHeight: 1
                    },
                }}
            >

                {/*<Button onClick={handleOpen}>Open modal</Button>*/}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Text in a modal
                        </Typography>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography>
                    </Box>
                </Modal>
                <DataGrid
                    checkboxSelection
                    rows={tableData}
                    columns={columns}
                    components={{Toolbar: GridToolbar}}
                />
            </Box>
        </Box>
    );
};

export default OptionsAll;
