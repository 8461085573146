import {Box, Button, IconButton, Tooltip, Typography, useTheme} from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import {mockActions} from "../../data/mockData";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import MetalsPieChartComponent from "../../components/MetalsPieChartComponent";
import MetalsLineChartComponent from "../../components/MetalsLineChartComponent";
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import CurrencyContext from "../../contexts/CurrencyContext";
import {useAuth} from "react-oidc-context";
import axios from "axios";
import StatusQuoShortLong from "../options/StatusQuoShortLong";
import StatusQuoOptions from "../options/StatusQuoOptions";
import StatusQuoCallsPuts from "../options/StatusQuoCallsPuts";
import {useTranslation} from "react-i18next";

const DashboardStatusQuo = () => {
    const navigate = useNavigate();
    const [navigateTo, setNavigateTo] = useState(null);
    const [currencyValueContext] = useContext(CurrencyContext);

    const { t } = useTranslation();

    useEffect(() => {
        const abortController = new AbortController();
        if (navigateTo) {
            const target = navigateTo;
            setNavigateTo(null);
            navigate(target);
        }
        return () => {
            // this will cancel the fetch request when the effect is unmounted
            abortController.abort();
        };
    }, [navigate, navigateTo])

    const handleRouterRedirect = (navigateTo) => {
        console.log("handleRouterRedirect: " + navigateTo);
        setNavigateTo(navigateTo);
    };

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [rateData, setRateData] = useState([]);
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const url = apiEndpoint + "/rate/latest?currency=" + currencyValueContext + "&type=line";
    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        axios.get(url, authConfig)
            .then((response) => {
                console.log(response);
                setRateData(response.data);
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [url])
    console.log(rateData);

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={t("pages.dashboardStatusQuo.title.short")} subtitle={t("pages.dashboardStatusQuo.title.long")}/>

                <Box>
                    <a href={require("../../files/report.pdf")} download="report.pdf">
                        <Button
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                        >
                            <DownloadOutlinedIcon sx={{mr: "10px"}}/>
                            {t("pages.dashboard.download.reports")}
                        </Button>
                    </a>
                </Box>
            </Box>

            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            >
                {/* ROW 0 */}
                <Box
                    gridColumn="span 3"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    margin="0px"

                >
                    <StatusQuoOptions  isDashboard={true}/>
                </Box>
                <Box
                    gridColumn="span 6"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    margin="0px"

                >
                    <StatusQuoShortLong  isDashboard={true}/>
                </Box>
                <Box
                    gridColumn="span 3"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    margin="0px"
                >
                    <StatusQuoCallsPuts  isDashboard={true}/>
                </Box>


                {/* ROW 1 */}

                <Box
                    gridColumn="span 5"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                >
                    <Box
                        mt="20px"
                        p="0 30px"
                        display="flex "
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight="600"
                                color={colors.grey[100]}
                            >
                                {t("price")}
                            </Typography>
                            <Typography
                                variant="h3"
                                fontWeight="bold"
                                color={colors.greenAccent[500]}
                            >
                                {t("gold")}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={() => handleRouterRedirect("/charts/metalsLineChart")}>
                                <Tooltip title={t("pages.dashboard.tip.metalsCharts")}
                                         componentsProps={{
                                             tooltip: {
                                                 sx: {
                                                     color: colors.grey[100],
                                                     fontSize: 16,
                                                     bgcolor: `rgba(0, 0, 0, 0)`,
                                                 },
                                             },
                                         }}
                                >
                                    <AutoGraphOutlinedIcon
                                        sx={{fontSize: "26px", color: colors.greenAccent[500]}}
                                    />
                                </Tooltip>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box height="250px" m="-20px 0 0 0">
                        <MetalsLineChartComponent isDashboard={true} currency={currencyValueContext}/>
                    </Box>
                </Box>

                <Box
                    gridColumn="span 4"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    overflow="auto"
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom={`2px solid ${colors.primary[900]}`}
                        colors={colors.grey[100]}
                        p="15px"
                    >
                        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                            {t("pages.dashboard.recentActions")}
                        </Typography>
                    </Box>
                    {mockActions.map((action, i) => (
                        <Box
                            key={`${action.actionId}-${i}`}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            // borderBottom={`4px solid red`}
                            borderBottom={`2px solid ${colors.primary[900]}`}
                            p="15px"
                        >
                            <Box>
                                <Typography
                                    color={colors.greenAccent[500]}
                                    variant="h5"
                                    fontWeight="600"
                                >
                                    {action.metal}
                                </Typography>

                                <Typography
                                    color={action.type === "PUT" ? colors.greenAccent[500] : colors.redAccent[500]}
                                    fontWeight="700">
                                    {action.type}
                                </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>{action.date}</Box>
                            <Box color={colors.grey[100]}>{action.cost}</Box>
                            <Box color={colors.grey[100]}>{action.oz}</Box>
                            <Box
                                backgroundColor={colors.greenAccent[500]}
                                p="5px 10px"
                                borderRadius="4px"
                            >
                                ${action.oz * action.cost}
                            </Box>
                        </Box>
                    ))}
                </Box>


                {/* ROW 1 */}

                <Box
                    gridColumn="span 3"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <MetalsPieChartComponent isDashboard={true}/>
                </Box>



            </Box>

        </Box>
    );
};

export default DashboardStatusQuo;
