import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Checkbox, FormControlLabel, FormGroup, Typography, useTheme} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import {useAuth} from "react-oidc-context";
import axios from "axios";
import CurrencyContext from "../../contexts/CurrencyContext";
import {NumericFormat} from "react-number-format";
import FormControl from "@mui/material/FormControl";
import {useTranslation} from "react-i18next";

const OptionsStatusQuo = () => {
    const [statusQuoData, setStatusQuoData] = useState({});
    const { t } = useTranslation();

    const [currencyValueContext] = useContext(CurrencyContext);
    const currencySymbol = currencyValueContext === "EUR" ? "€" : "$";
    const eurCurrencySymbol = "€";
    const auth = useAuth();

    const [rateData, setRateData] = useState([]);
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const urlRate = apiEndpoint + "/rate/latest?currency=" + currencyValueContext + "&type=line";
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        const authToken = auth.user.access_token;
        const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };        axios.get(urlRate, authConfig)
            .then((response) => {
                console.log(response);
                setRateData(response.data);
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [urlRate])


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const statusquoUrl = apiEndpoint + "/optionsStatusQuo";
    useEffect(() => {
        axios.get(statusquoUrl, authConfig)
            .then((response) => {
                console.log(response);
                setStatusQuoData(response.data);
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [])
    console.log(statusQuoData)

    function formatAsPercent(params) {
        return `${parseFloat(params).toFixed(2)}%`;
    }

    function formatAsInt(params) {
        return `${parseFloat(params.value).toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&.')}`;
    }

    function formatAsDouble(params) {
        if (params === null) {
            return "";
        }
        if (params !== undefined && params.value !== undefined) {
            return `${parseFloat(params.value).toFixed(2).replace(/\./g, ',').replace(/\d(?=(\d{3})+,)/g, '$&.')}`;
        }
        if (params !== undefined && params.value === undefined) {
            return `${parseFloat(params).toFixed(2).replace(/\./g, ',').replace(/\d(?=(\d{3})+,)/g, '$&.')}`;
        }
        console.log("** " + JSON.stringify(params.value));

        return 777;
    }
    const columns = [
        {
            field: "optionType",
            headerName: t("table.optionType"),
            flex: 1,
            headerAlign: "right",
            align: "right",
        },
        {
            field: "metal",
            headerName: t("table.metal"),
            flex: 1,
            headerAlign: "right",
            align: "right",
        },
        {
            field: "date",
            flex: 1,
            headerName: t("table.date"),
            type: "date",
            headerAlign: "right",
            align: "right",
        },
        {
            field: "endDate",
            flex: 1,
            headerName: t("table.endDate"),
            type: "date",
            headerAlign: "right",
            align: "right",
        },
        {
            field: "price",
            flex: 1,
            headerName: t("table.price"),
            valueFormatter: formatAsDouble,
            type: "number",
            headerAlign: "right",
            align: "right",
        },
        {
            field: "strike",
            flex: 1,
            headerName: t("table.strike"),
            valueFormatter: formatAsDouble,
            type: "number",
            headerAlign: "right",
            align: "right",
        },
        // {
        //     field: "abst",
        //     flex: 1,
        //     headerName: "Abst %",
        //     valueFormatter: formatAsPercent,
        //     type: "number",
        //     headerAlign: "right",
        //     align: "right",
        // },

        {
            field: "abst",
            headerName: t("table.abst"),
            type: "number",
            headerAlign: "right",
            align: "right",
            flex: 1,
            renderCell: ({row: {abst}}) => {
                return (
                    <Typography
                        color={abst < 0 ? colors.redAccent[500] : colors.greenAccent[500]}
                    >
                        {formatAsPercent(abst)}
                    </Typography>
                );
            },
        },


        {
            field: "oz",
            flex: 1,
            headerName: t("table.oz"),
            valueFormatter: formatAsInt,
            type: "number",
            headerAlign: "right",
            align: "right",
        },
        {
            field: "capital",
            flex: 1,
            valueFormatter: formatAsDouble,
            type: "number",
            headerName: t("table.capital"),
            headerAlign: "right",
            align: "right"
        },
        {
            field: "prime",
            flex: 1,
            valueFormatter: formatAsDouble,
            type: "number",
            headerName: t("table.prime"),
            headerAlign: "right",
            align: "right"
        },
        {
            field: "loss",
            headerName: t("table.loss"),
            type: "number",
            headerAlign: "right",
            align: "right",
            flex: 1,
            renderCell: ({row: {loss}}) => {
                return (
                    <Typography
                        color={loss < 0 ? colors.redAccent[500] : colors.greenAccent[500]}
                    >
                        {formatAsDouble(loss)}
                    </Typography>
                );
            },
        },
        {
            field: "saldo",
            headerName: t("table.saldo"),
            type: "number",
            headerAlign: "right",
            align: "right",
            flex: 1,
            renderCell: ({row: {saldo}}) => {
                return (
                    <Typography
                        color={saldo < 0 ? colors.redAccent[500] : colors.greenAccent[500]}
                    >
                        {formatAsDouble(saldo)}
                    </Typography>
                );
            },
        },
        {
            field: "days",
            flex: 1,
            headerName: t("table.days"),
            valueFormatter: formatAsInt,
            type: "number",
            headerAlign: "right",
            align: "right",
        },
        {
            field: "status",
            flex: 1,
            headerAlign: "right",
            align: "right",
            headerName: t("table.status"),
        },
    ];


    const [metalState, setMetalState] = useState({
        au: true,
        ag: true,
        pt: true
    });
    const [typeState, setTypeState] = useState({
        call: true,
        put: true
    });

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setState({
    //         ...state,
    //         [event.target.name]: event.target.checked,
    //     });
    // };

    const handleMetalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMetalState({
            ...metalState,
            [event.target.name]: event.target.checked,
        });
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTypeState({
            ...typeState,
            [event.target.name]: event.target.checked,
        });
    };


    const { au, ag, pt} = metalState;
    const {call, put } = typeState;


    const handleFormFilterSend = () => {
        const authToken = auth.user.access_token;
        const searchParams = new URLSearchParams();
        Object.keys(metalState).forEach(function (key) {
            if (metalState[key] === true) {
                searchParams.append('metals', key);
            }
        });
        Object.keys(typeState).forEach(function (key) {
            if (typeState[key] === true) {
                searchParams.append('types', key);
            }
        });
        const params = {
            headers: {Authorization: `Bearer ${authToken}`} ,
            params: searchParams,
        };
        axios.get(statusquoUrl, params)
            .then((response) => {
                console.log(response);
                setStatusQuoData(response.data);
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    };


    return (
        <Box m="20px">
            <Header
                title={t("pages.statusQuo.title.short")}
                subtitle={t("pages.statusQuo.title.long")}
            />


            <Box
                m="10px 0 0 0"
                height="70vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        textOverflow: "clip",
                        whiteSpace: "break-spaces",
                        lineHeight: 1
                    },
                }}
            >

                <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap="20px"
                >


                    {/* ROW 1 */}
                    <Box
                        gridColumn="span 3"
                        gridRow="span 1"
                    >
                        {statusQuoData && statusQuoData.total && <Box width="100%" p="10px">
                            <Box display="flex" width="100%" justifyContent="center">
                                <Box>
                                    {t("pages.dashboard.summary.status")}
                                </Box>
                            </Box>
                            {statusQuoData.total.prime !== null && <Box display="flex" width="100%" justifyContent="space-between" mt="10px">
                                <Box>
                                    {t("pages.dashboard.summary.prime")}
                                </Box>
                                <Box>
                                    <Typography
                                        fontWeight="bold"
                                        color={statusQuoData.total.prime < 0 ? colors.redAccent[500] : colors.greenAccent[500]}
                                    >
                                        <NumericFormat value={statusQuoData.total.prime}  suffix={' ' + eurCurrencySymbol + ' '} thousandSeparator="." decimalSeparator="," decimalScale={2} fixedDecimalScale displayType="text" />
                                    </Typography>
                                </Box>
                            </Box>}
                            <Box display="flex" width="100%" justifyContent="space-between" mt="10px">
                                <Box>
                                    {t("pages.dashboard.summary.loss")}
                                </Box>
                                <Box>
                                    <Typography
                                        fontWeight="bold"
                                        color={statusQuoData.total.loss < 0 ? colors.redAccent[500] : colors.greenAccent[500]}
                                    >
                                        <NumericFormat value={statusQuoData.total.loss}  suffix={' ' + eurCurrencySymbol + ' '} thousandSeparator="." decimalSeparator="," decimalScale={2} fixedDecimalScale displayType="text" />
                                    </Typography>

                                </Box>
                            </Box>
                            <Box display="flex" width="100%" justifyContent="space-between" mt="10px">
                                <Box>
                                    {t("pages.dashboard.summary.saldo")}
                                </Box>
                                <Box>
                                    <Typography
                                        fontWeight="bold"
                                        color={statusQuoData.total.saldo < 0 ? colors.redAccent[500] : colors.greenAccent[500]}
                                    >
                                        <NumericFormat value={statusQuoData.total.saldo}  suffix={' ' + eurCurrencySymbol + ' '} thousandSeparator="." decimalSeparator="," decimalScale={2} fixedDecimalScale  displayType="text" />
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>}
                    </Box>

                    {/* ROW 1 */}
                    <Box
                        gridColumn="span 3"
                        gridRow="span 1"
                    >
                        <Box width="100%" p="10px">
                            <Box display="flex" width="100%" justifyContent="center">
                                <Box>
                                    {rateData.modifyDate}
                                </Box>
                            </Box>
                            <Box display="flex" width="100%" justifyContent="space-between" mt="10px">
                                <Box>
                                    AU
                                </Box>
                                <Box>
                                    <NumericFormat value={rateData.gold}  suffix={' ' + currencySymbol + ' '} thousandSeparator="." decimalSeparator="," decimalScale={4} displayType="text" />
                                </Box>
                            </Box>
                            <Box display="flex" width="100%" justifyContent="space-between">
                                <Box>
                                    AG
                                </Box>
                                <Box>
                                    <NumericFormat value={rateData.silver}  suffix={' ' + currencySymbol + ' '} thousandSeparator="." decimalSeparator="," decimalScale={4} displayType="text" />
                                </Box>
                            </Box>
                            <Box display="flex" width="100%" justifyContent="space-between">
                                <Box>
                                    PT
                                </Box>
                                <Box>
                                    <NumericFormat value={rateData.platinum}  suffix={' ' + currencySymbol + ' '} thousandSeparator="." decimalSeparator="," decimalScale={4} displayType="text" />
                                </Box>
                            </Box>
                            <Box display="flex" width="100%" justifyContent="space-between">
                                <Box>
                                    USD
                                </Box>
                                <Box>
                                    <NumericFormat value={rateData.usd}  suffix={' ' + currencySymbol + ' '} thousandSeparator="." decimalSeparator="," decimalScale={4} displayType="text" />
                                </Box>
                            </Box>
                        </Box>
                    </Box>


                    {/* ROW 1 */}
                    <Box
                        gridColumn="span 2"
                        gridRow="span 1"
                    >
                        <Box width="100%" p="10px">
                            <Box display="flex" width="100%" justifyContent="center">
                                <Box justifyContent="center">
                                    <Box  justifyContent="center">
                                        {t("metal")}
                                    </Box>
                                    <Box>
                                        <FormControl
                                            component="fieldset"
                                            sx={{ m: 0 }}
                                            variant="standard"
                                        >
                                            {/*<FormLabel component="legend">Type</FormLabel>*/}
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={au} onChange={handleMetalChange} name="au"  size="small"/>
                                                    }
                                                    label="AU"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={ag} onChange={handleMetalChange} name="ag"  size="small"/>
                                                    }
                                                    label="AG"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={pt} onChange={handleMetalChange} name="pt"  size="small"/>
                                                    }
                                                    label="PT"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {/* ROW 1 */}
                    <Box
                        gridColumn="span 2"
                        gridRow="span 1"
                    >
                        <Box width="100%" p="10px">
                            <Box display="flex" width="100%" justifyContent="center">
                                <Box   justifyContent="center">
                                    <Box  justifyContent="center">
                                        {t("type")}
                                    </Box>
                                    <Box>
                                        <FormControl
                                            component="fieldset"
                                            sx={{ m: 0 }}
                                            variant="standard"
                                        >
                                            {/*<FormLabel component="legend">Type</FormLabel>*/}
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={call} onChange={handleTypeChange} name="call" size="small" />
                                                    }
                                                    label="Call"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={put} onChange={handleTypeChange} name="put" size="small"/>
                                                    }
                                                    label="Put"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {/* ROW 1 */}
                    <Box
                        gridColumn="span 2"
                        gridRow="span 1"
                    >
                        <Box width="100%" p="10px">
                            <Box display="flex" width="100%" justifyContent="center">
                                <Box width="100%" p="10px">
                                    <Box display="flex" width="100%" justifyContent="center">
                                        <Button type="submit" color="button" variant="contained"
                                                onClick={() => handleFormFilterSend()}>
                                            {t("pages.statusQuo.applyFilters")}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                </Box>



                {statusQuoData.options && <DataGrid
                    // checkboxSelection
                    rows={statusQuoData.options}
                    columns={columns}
                    components={{Toolbar: GridToolbar}}
                />}
            </Box>
        </Box>
    );
};

export default OptionsStatusQuo;
