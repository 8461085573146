import React, {useEffect, useState} from "react";
import {Box, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import {useAuth} from "react-oidc-context";
import axios from "axios";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';



const StatusQuoShortLong = ({isDashboard = false}) => {
    const [statisticData, setStatisticData] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const url = apiEndpoint + "/optionsStatistics";
    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        axios.get(url, authConfig)
            .then((response) => {
                console.log(response);
                setStatisticData(response.data);
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [])
    console.log(statisticData);


    function formatAsPercent(num) {
        return `${parseFloat(num).toFixed(2)}%`;
    }

    const columns = [
        { id: 'key', label: 'Metal', align: 'center', minWidth: 30  },
        { id: 'valueA', label: '', align: 'center', minWidth: 30, maxWidth: 40  },
        { id: 'percentValueA', label: '', align: 'center', minWidth: 30, maxWidth: 40, format: formatAsPercent},
        { id: 'valueB', label: '', align: 'center', minWidth: 30, maxWidth: 40 },
        { id: 'percentValueB', label: '', align: 'center', minWidth: 30, maxWidth: 40, format: formatAsPercent},
    ];

    return (
        <Box sx={{ width: '100%', margin: '10px' }}>
            <Box
                sx={{
                    "& .MuiTableCell-head": {
                        backgroundColor: isDashboard ? colors.primary[400] : colors.blueAccent[700],
                        padding: '10px'
                    },
                    "& .MuiTableCell-body": {
                        backgroundColor: colors.primary[400],
                        padding: '10px'
                        // borderBottom: "none",
                        // border: "solid 1px",
                        // borderColor: colors.primary[300]
                    },
                }}
            >
                <Paper>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" colSpan={1}>
                                    </TableCell>
                                    <TableCell align="center" colSpan={2}>
                                        Calls
                                    </TableCell>
                                    <TableCell align="center" colSpan={2}>
                                        Puts
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {statisticData.callsPutsOptions && statisticData.callsPutsOptions                                    // .slice(0, 10)
                                    // .slice(0, 10)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.metal}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align} maxWidth={column.maxWidth} small='small'>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        </Box>
    );





};

export default StatusQuoShortLong;







