import {Box, Button, IconButton, Tooltip, Typography, useTheme} from "@mui/material";
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import {mockActions} from "../../data/mockData";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import MetalsPieChartComponent from "../../components/MetalsPieChartComponent";
import MetalsLineChartComponent from "../../components/MetalsLineChartComponent";
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import RateBox from "../../components/RateBox";
import CurrencyContext from "../../contexts/CurrencyContext";
import {useAuth} from "react-oidc-context";
import axios from "axios";
import StatusQuoOptions from "../options/StatusQuoOptions";
import {NumericFormat} from "react-number-format";
import { useTranslation } from 'react-i18next'

const Dashboard = () => {
    const navigate = useNavigate();
    const [navigateTo, setNavigateTo] = useState(null);
    const [currencyValueContext] = useContext(CurrencyContext);
    const currencySymbol = currencyValueContext === "EUR" ? "€" : "$";
    const eurCurrencySymbol = "€";

    useEffect(() => {
        const abortController = new AbortController();
        if (navigateTo) {
            const target = navigateTo;
            setNavigateTo(null);
            navigate(target);
        }
        return () => {
            // this will cancel the fetch request when the effect is unmounted
            abortController.abort();
        };
    }, [navigate, navigateTo])

    const handleRouterRedirect = (navigateTo) => {
        console.log("handleRouterRedirect: " + navigateTo);
        setNavigateTo(navigateTo);
    };

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [rateData, setRateData] = useState([]);
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const url = apiEndpoint + "/rate/latest?currency=" + currencyValueContext + "&type=line";
    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        axios.get(url, authConfig)
            .then((response) => {
                console.log(response);
                setRateData(response.data);
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [url])

    const { t } = useTranslation();

    const [statusQuoData, setStatusQuoData] = useState({});

    const urlStatusQuo = apiEndpoint + "/optionsStatusQuo";
    useEffect(() => {
        axios.get(urlStatusQuo, authConfig)
            .then((response) => {
                console.log(response);
                setStatusQuoData(response.data);
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [urlStatusQuo])

    console.log(statusQuoData)

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title={t("pages.dashboard.title.short") } subtitle={t("pages.dashboard.title.long")}/>
                <Box>
                    <a href={require("../../files/report.pdf")} download="report.pdf">
                        <Button
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                        >
                            <DownloadOutlinedIcon sx={{mr: "10px"}}/>
                            {t("pages.dashboard.download.reports")}
                        </Button>
                    </a>
                </Box>
            </Box>

            {/* GRID & CHARTS */}
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="140px"
                gap="20px"
            >
                {/* ROW 1 */}


                <Box
                    gridColumn="span 3"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box width="100%" m="0 30px">
                        <Box display="flex" justifyContent="space-between">
                            <Box mt="15px">
                                <Typography
                                    variant="h5"
                                    fontWeight="600"
                                    color={colors.grey[100]}
                                >
                                    {t("pages.dashboard.statusQuo")}
                                </Typography>
                            </Box>
                            <Box mt="5px" >
                                <IconButton onClick={() => handleRouterRedirect("/dashboardStatus")}>
                                    <Tooltip title={t("pages.dashboard.tip.statusQuo")}
                                             placement="top"
                                             componentsProps={{
                                                 tooltip: {
                                                     sx: {
                                                         color: colors.grey[100],
                                                         fontSize: 16,
                                                         bgcolor: `rgba(0, 0, 0, 0)`,
                                                     },
                                                 },
                                             }}
                                    >
                                        <BackupTableOutlinedIcon
                                            sx={{fontSize: "26px", color: colors.greenAccent[500]}}
                                        />
                                    </Tooltip>
                                </IconButton>
                            </Box>


                        </Box>
                        <StatusQuoOptions isDashboard={true} />
                    </Box>
                </Box>

                <Box
                    gridColumn="span 5"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                >
                    <Box
                        mt="15px"
                        p="0 20px"
                        display="flex "
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight="600"
                                color={colors.grey[100]}
                            >
                                {t("price")}
                            </Typography>
                            <Typography
                                variant="h3"
                                fontWeight="bold"
                                color={colors.greenAccent[500]}
                            >
                                {t("gold")}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={() => handleRouterRedirect("/charts/metalsLineChart")}>
                                <Tooltip title={t("pages.dashboard.tip.metalsCharts")}
                                         placement="top"
                                         componentsProps={{
                                             tooltip: {
                                                 sx: {
                                                     color: colors.grey[100],
                                                     fontSize: 16,
                                                     bgcolor: `rgba(0, 0, 0, 0)`,
                                                 },
                                             },
                                         }}
                                >
                                    <AutoGraphOutlinedIcon
                                        sx={{fontSize: "26px", color: colors.greenAccent[500]}}
                                    />
                                </Tooltip>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box height="250px" m="-20px 0 0 0">
                        <MetalsLineChartComponent isDashboard={true} currency={currencyValueContext}/>
                    </Box>
                </Box>



                <Box
                    gridColumn="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <RateBox
                        price={rateData.gold}
                        deltaPrice={rateData.deltaGold}
                        currencySymbol={currencySymbol}
                        date={rateData.modifyDate}
                        title={t("gold")}
                        progress="0.55"
                        icon={
                            <LocalMallOutlinedIcon
                                sx={{color: colors.greenAccent[600], fontSize: "26px"}}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <RateBox
                        price={rateData.silver}
                        deltaPrice={rateData.deltaSilver}
                        currencySymbol={currencySymbol}
                        date={rateData.modifyDate}
                        title={t("silver")}
                        progress="0.74"
                        icon={
                            <LocalMallOutlinedIcon
                                sx={{color: colors.greenAccent[600], fontSize: "26px"}}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <RateBox
                        price={rateData.platinum}
                        deltaPrice={rateData.deltaPlatinum}
                        currencySymbol={currencySymbol}
                        date={rateData.modifyDate}
                        title={t("platinum")}
                        progress="0.30"
                        icon={
                            <LocalMallOutlinedIcon
                                sx={{color: colors.greenAccent[600], fontSize: "26px"}}
                            />
                        }
                    />
                </Box>
                <Box
                    gridColumn="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <RateBox
                        price={rateData.palladium}
                        deltaPrice={rateData.deltaPalladium}
                        currencySymbol={currencySymbol}
                        date={rateData.modifyDate}
                        title={t("palladium")}
                        progress="0.20"
                        icon={
                            <LocalMallOutlinedIcon
                                sx={{color: colors.greenAccent[600], fontSize: "26px"}}
                            />
                        }
                    />
                </Box>

                {/* ROW 2 */}

                <Box
                    gridColumn="span 5"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    { statusQuoData.total && <Box width="100%" m="0 30px">
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    sx={{color: colors.grey[100]}}
                                >
                                    {t("pages.dashboard.summary.prime")}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    sx={{color: statusQuoData.total.prime >= 0 ? colors.greenAccent[600] : colors.redAccent[500]}}
                                >
                                    <NumericFormat value={statusQuoData.total.prime}  suffix={' ' + eurCurrencySymbol + ' '} thousandSeparator="." decimalSeparator="," decimalScale={4} displayType="text" />
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    sx={{color: colors.grey[100]}}
                                >
                                    {t("pages.dashboard.summary.loss")}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    sx={{color: statusQuoData.total.loss >= 0 ? colors.greenAccent[600] : colors.redAccent[500]}}
                                >
                                    <NumericFormat value={statusQuoData.total.loss}  suffix={' ' + eurCurrencySymbol + ' '} thousandSeparator="." decimalSeparator="," decimalScale={4} displayType="text" />
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    sx={{color: colors.grey[100]}}
                                >
                                    {t("pages.dashboard.summary.saldo")}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    sx={{color: statusQuoData.total.saldo >= 0 ? colors.greenAccent[600] : colors.redAccent[500]}}
                                >
                                    <NumericFormat value={statusQuoData.total.saldo}  suffix={' ' + eurCurrencySymbol + ' '} thousandSeparator="." decimalSeparator="," decimalScale={4} displayType="text" />
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    sx={{color: colors.grey[100]}}
                                >
                                    {t("pages.dashboard.summary.totalClaims")}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    sx={{color: colors.redAccent[500]}}
                                >
                                    -15 000 000 {currencySymbol}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    sx={{color: colors.grey[100]}}
                                >
                                    {t("pages.dashboard.summary.equailty")}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    sx={{color: colors.grey[100]}}
                                >
                                    10 654 344 {currencySymbol}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    sx={{color: colors.grey[100]}}
                                >
                                    {t("pages.dashboard.summary.overCollateral")}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="h4"
                                    fontWeight="bold"
                                    sx={{color: colors.greenAccent[500]}}
                                >
                                    45.3 %
                                </Typography>
                            </Box>
                        </Box>
                    </Box>}
                </Box>


                <Box
                    gridColumn="span 3"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <MetalsPieChartComponent isDashboard={true}/>
                </Box>


                <Box
                    gridColumn="span 4"
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    overflow="auto"
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom={`2px solid ${colors.primary[900]}`}
                        colors={colors.grey[100]}
                        p="15px"
                    >
                        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                            {t("pages.dashboard.recentActions")}
                        </Typography>
                    </Box>
                    {mockActions.map((action, i) => (
                        <Box
                            key={`${action.actionId}-${i}`}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            // borderBottom={`4px solid red`}
                            borderBottom={`2px solid ${colors.primary[900]}`}
                            p="15px"
                        >
                            <Box>
                                <Typography
                                    color={colors.greenAccent[500]}
                                    variant="h5"
                                    fontWeight="600"
                                >
                                    {action.metal}
                                </Typography>

                                <Typography
                                    color={action.type === "PUT" ? colors.greenAccent[500] : colors.redAccent[500]}
                                    fontWeight="700">
                                    {action.type}
                                </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>{action.date}</Box>
                            <Box color={colors.grey[100]}>{action.cost}</Box>
                            <Box color={colors.grey[100]}>{action.oz}</Box>
                            <Box
                                backgroundColor={colors.greenAccent[500]}
                                p="5px 10px"
                                borderRadius="4px"
                            >
                                ${action.oz * action.cost}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
