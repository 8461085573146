import React, {useState, useEffect} from 'react'
import {Box, useTheme} from "@mui/material";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import {useAuth} from "react-oidc-context";
import axios from "axios";
import { JsonView, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';


const UserInfo = () => {
    const [userInfo, setUserInfo] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const url = apiEndpoint + "/userinfo";
    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        axios.get(url, authConfig)
            .then((response) => {
                console.log(response);
                setUserInfo(JSON.stringify(response.data, null, 2));
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [])
    console.log(userInfo)


    return (
        <Box m="20px">
            <Header title="Users" subtitle="User Info"/>
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >

                <JsonView data={userInfo} shouldInitiallyExpand={(level) => true} style={defaultStyles} />
                <JsonView data={userInfo} shouldInitiallyExpand={(level) => true} style={darkStyles} />
                {/*{userInfo}*/}
            </Box>
        </Box>
    );
};

export default UserInfo;
