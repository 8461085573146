import {useState} from "react";
import {Box, Button, TextField, useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Formik} from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import {tokens} from "../../theme";

const NewMessages = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [formData, setFormData] = useState(null);

    const isNonMobile = useMediaQuery("(min-width:600px)");

    const handleFormReset = () => {
        setFormData(null);
    };


    return (
        <Box m="20px">
            <Header title="New Messages" subtitle="Send New Message" />
            <Box
                sx={
                    {
                        "& input[type='date']::-webkit-calendar-picker-indicator": {
                            filter: 'invert(0.5)',
                        },
                        "& input[type='time']::-webkit-calendar-picker-indicator": {
                            filter: 'invert(0.5)',
                        },
                        "& .Mui-focused": {
                            color: colors.greenAccent[300],
                        },
                        "& label.Mui-focused": {
                            color: colors.greenAccent[300],
                        },
                        "& button.delete": {
                            backgroundColor: colors.redAccent[300],
                        },
                    }}
            >
                <Formik
                    onSubmit={(values, event) => {
                        // sendAjax(url, values, "POST");
                    }}
                    enableReinitialize
                    initialValues={formData || initialValues}
                    validationSchema={checkoutSchema}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          isSubmitting,
                          handleSubmit,
                          dirty,
                          isValid
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                                }}
                            >
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="To"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.to || ""}
                                    name="to"
                                    error={!!touched.to && !!errors.to}
                                    helperText={touched.to && errors.to}
                                    sx={{gridColumn: "span 2"}}
                                />
                                <Box sx={{gridColumn: "span 2"}}/>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Subject"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.subject || ""}
                                    name="subject"
                                    error={!!touched.subject && !!errors.subject}
                                    helperText={touched.subject && errors.subject}
                                    sx={{gridColumn: "span 2"}}
                                />
                                <Box sx={{gridColumn: "span 2"}}/>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    multiline
                                    rows={7}
                                    label="Text"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.text || ""}
                                    name="text"
                                    error={!!touched.text && !!errors.text}
                                    helperText={touched.text && errors.text}
                                    sx={{gridColumn: "span 2"}}
                                />
                                <Box sx={{gridColumn: "span 2"}}/>

                                <Box display="flex" justifyContent="space-between" mt="20px" sx={{gridColumn: "span 2"}}>
                                    <Box>
                                        <Button type="submit" color="button" variant="contained" disabled={!dirty || !isValid}>
                                            Send
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button type="reset" color="caution" variant="contained" onClick={handleFormReset}
                                                sx={{marginRight: "20px"}}>
                                            Reset
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    to: yup.string().required("required"),
    subject: yup.string().required("required"),
    text: yup.string().required("required"),
});

const initialValues = {
    to: "Admin",
    subject: "",
    text: "",
};

export default NewMessages;


