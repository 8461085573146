import { Box } from "@mui/material";
import Header from "../../components/Header";
import MetalsPieChartComponent from "../../components/MetalsPieChartComponent";

const MetalsPieChart = () => {
  return (
    <Box m="20px">
      <Header title="Metals Pie Chart" subtitle="Precious metals Pie Chart" />
      <Box height="75vh">
        <MetalsPieChartComponent isDashboard={false}/>
      </Box>
    </Box>
  );
};

export default MetalsPieChart;
