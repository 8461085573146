import {Box, Button, useTheme} from "@mui/material";
import Header from "../../components/Header";
import Typography from "@mui/material/Typography";
import { tokens } from "../../theme";
import axios from "axios";
import {useAuth} from "react-oidc-context";

const ServerInit = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const auth = useAuth();

    const authToken = auth.user.access_token;
    function sendAjax(url, values, method) {
        axios({
            method: method,
            url: url,
            data: values,
            headers:  {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authToken
            }
        }).then((response) => {
            console.log(response);
        }, (error) => {
            console.log(error);
            console.error('There was an error!', error);
            if (error.response.status === 401) {
                auth.signinRedirect();
            }
        });
    }

    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const urlInit = apiEndpoint + "/init/";
    const urlClear = apiEndpoint + "/clear/";
    const handleFormInit = (part) => {
        sendAjax(urlInit + part, "", "GET");
    };
    const handleFormClear = (part) => {
        sendAjax(urlClear + part, "", "GET");
    };


    return (
    <Box m="20px">
      <Header title="Server Init - be careful!!!" subtitle="List of Server Init links" />

        <Typography
            variant="h4"
            color={colors.grey[200]}
            fontWeight="700"
            sx={{ m: "25px 0 15px 0" }}
        >
        Init will start without any confirmation
      </Typography>

        <Box mb="25px">
            <Button type="button" color="caution" variant="contained"
                    onClick={() => handleFormInit("users")}>
                Init users
            </Button>
        </Box>

        <Box mb="25px">
            <Button type="button" color="caution" variant="contained"
                    onClick={() => handleFormInit("rates")}>
                Init Rates
            </Button>
        </Box>

        <Box mb="25px">
            <Button type="button" color="caution" variant="contained"
                    onClick={() => handleFormInit("metals")}>
                Init Metals
            </Button>
        </Box>

        <Box mb="25px">
            <Button type="button" color="caution" variant="contained"
                    onClick={() => handleFormInit("options")}>
                Init Options
            </Button>
        </Box>

        <Box mb="25px">
            <Button type="button" color="caution" variant="contained"
                    onClick={() => handleFormInit("all")}>
                Init All
            </Button>
        </Box>

        <Box mt="50px">
            <Typography
                variant="h4"
                color={colors.grey[200]}
                fontWeight="700"
                sx={{ m: "25px 0 15px 0" }}
            >
                Clear will start without any confirmation
            </Typography>
        </Box>

        <Box mb="25px">
            <Button type="button" color="caution" variant="contained"
                    onClick={() => handleFormClear("users")}>
                Clear users
            </Button>
        </Box>

        <Box mb="25px">
            <Button type="button" color="caution" variant="contained"
                    onClick={() => handleFormClear("rates")}>
                Clear Rates
            </Button>
        </Box>

        <Box mb="25px">
            <Button type="button" color="caution" variant="contained"
                    onClick={() => handleFormClear("metals")}>
                Clear Metals
            </Button>
        </Box>

        <Box mb="25px">
            <Button type="button" color="caution" variant="contained"
                    onClick={() => handleFormClear("options")}>
                Clear Options
            </Button>
        </Box>

        <Box mb="25px">
            <Button type="button" color="caution" variant="contained"
                    onClick={() => handleFormClear("all")}>
                Clear All
            </Button>
        </Box>

    </Box>
  );
};

export default ServerInit;
