import React, {useEffect, useState} from "react";
import {Link as RouteLink} from "react-router-dom";
import {Box, useTheme} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import MetalsPieChartComponent from "../../components/MetalsPieChartComponent";
import {useAuth} from "react-oidc-context";
import axios from "axios";

const MetalsBalances = () => {
    const [tableData, setTableData] = useState([]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const url = apiEndpoint + "/metals";
    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        axios.get(url, authConfig)
            .then((response) => {
                console.log(response);
                setTableData(response.data);
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [])

    console.log(tableData)

    const columns = [
        {
            field: "action",
            headerName: "",
            flex: 0.4,
            renderCell: ({row: {id}}) => {
                return (
                    <Box>
                        <RouteLink to={"/metals/edit/" + id}>
                            <EditOutlinedIcon sx={{color: colors.greenAccent[500]}}/>
                        </RouteLink>
                    </Box>
                );
            },
        },
        {field: "metal", headerName: "Metal", flex: 1},
        {field: "bar", headerName: "Weight (oz)", flex: 1},
        {
            field: "quantity",
            flex: 1,
            headerName: "Quantity",
            type: "number",
            headerAlign: "left",
            align: "left",
        }
    ];

    return (
        <Box m="20px">
            <Header
                title="Metals Balances"
                subtitle="List of Metals Balances"
            />

            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap="20px"
            >
                <Box
                    gridColumn="span 7"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box
                        height="75vh"
                        width="100%"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: colors.greenAccent[300],
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.blueAccent[700],
                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                textOverflow: "clip",
                                whiteSpace: "break-spaces",
                                lineHeight: 1
                            },
                        }}
                    >
                        <DataGrid
                            rows={tableData}
                            columns={columns}
                            components={{Toolbar: GridToolbar}}
                        />
                    </Box>
                </Box>

                <Box
                    gridColumn="span 5"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding="20px"
                >
                    <Box
                        height="75vh"
                        width="100%"
                    >
                        <MetalsPieChartComponent/>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default MetalsBalances;
