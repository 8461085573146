import React from 'react'
import {useAuth} from "react-oidc-context";
import {Navigate, Outlet} from "react-router-dom";

const OutletProtectedRoute = () => {

    const auth = useAuth();

    if (auth.isLoading) {
        return <div>Loading...</div>;

    }
    if (auth.error) {
        console.log("auth error: " + auth.error.message)
        auth.removeUser();
        return <Navigate to="/login" replace/>;
    }
    if (!auth.isAuthenticated) {
        console.log("no auth")
        return <Navigate to="/login" replace/>;
    }
    return <Outlet/>;

}

export default OutletProtectedRoute
