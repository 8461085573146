import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationsInEn from './locales/en/translation.json';
import translationsInDe from './locales/de/translation.json';
import translationsInRu from './locales/ru/translation.json';

const resources = {
    en: {
        translation: translationsInEn
    },
    de: {
        translation: translationsInDe
    },
    ru: {
        translation: translationsInRu
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("lang"), // It acts as default language. When the site loads, content is shown in this language.
        debug: true,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        ns: "translation",
        defaultNS: "translation"
    });

export default i18n;
