import React, {useEffect, useState} from "react";

import {useContext} from "react";
import {Box, IconButton, useTheme} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {ColorModeContext, tokens} from "../../theme";
import {Menu, MenuItem} from "@mui/material";

import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import { useNavigate } from "react-router-dom";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import CurrencyContext from "../../contexts/CurrencyContext";
import {useAuth} from "react-oidc-context";
import i18n from "../../i18n";
import {useTranslation} from "react-i18next";


const Topbar = () => {
    const auth = useAuth();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const [currencyValueContext, setCurrencyValueContext] = useContext(CurrencyContext);

    const [anchorAccountEl, setAnchorAccountEl] = useState(null);
    const [anchorSettingsEl, setAnchorSettingsEl] = useState(null);
    const [anchorMessagesEl, setAnchorMessagesEl] = useState(null);
    const openAccount = Boolean(anchorAccountEl);
    const openSettings = Boolean(anchorSettingsEl);
    const openMessages = Boolean(anchorMessagesEl);
    const navigate = useNavigate();

    const [navigateTo, setNavigateTo] = useState(null);
    const { t } = useTranslation();



    // const [currency, setCurrency] = React.useState('EUR');

    const handleChangeCurrency = (event: SelectChangeEvent) => {
        console.log(event.target);
        console.log(event.target.value);
        setCurrencyValueContext(event.target.value);
    };

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const chooseLanguage = (e) => {
        e.preventDefault();
        i18n.changeLanguage(e.target.value);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(e.target.value);
        localStorage.setItem("lang", e.target.value);
    }


    // var navigateTo;

    useEffect(() => {
        const abortController = new AbortController();
        if (navigateTo) {
            const target = navigateTo;
            setNavigateTo(null);
            navigate(target);
        }
        return () => {
            // this will cancel the fetch request when the effect is unmounted
            abortController.abort();
        };
    }, [navigate, navigateTo])

    const closeAllMenu = () => {
        setAnchorMessagesEl(null);
        setAnchorSettingsEl(null);
        setAnchorAccountEl(null);
        console.log("closeAllMenu")
    };

    const handleAccountClick = (event: MouseEvent<HTMLButtonElement>) => {
        console.log("handleAccountClick")
        closeAllMenu();
        setAnchorAccountEl(event.currentTarget);
    };

    const handleSettingsClick = (event: MouseEvent<HTMLButtonElement>) => {
        console.log("handleSettingsClick")
        closeAllMenu();
        setAnchorSettingsEl(event.currentTarget);
    };

    const handleMessagesClick = (event: MouseEvent<HTMLButtonElement>) => {
        console.log("handleMessagesClick");
        closeAllMenu();
        setAnchorMessagesEl(event.currentTarget);
    };

    const handleAccountClose = () => {
        console.log("handleAccountClose")
        closeAllMenu();
    };

    const handleLogout = () => {
        auth.signoutRedirect();
        console.log("handleLogout")
        closeAllMenu();
    };

    const handleSettingsClose = () => {
        console.log("handleSettingsClose")
        closeAllMenu();
    };

    const handleMessagesClose = () => {
        console.log("handleMessagesClose")
        closeAllMenu();
    };

    const handleRouterRedirect = (navigateTo) => {
        console.log("handleRouterRedirect");
        console.log(navigateTo);
        // closeAllMenu();
        setNavigateTo(navigateTo);
    };

    if (!auth.isAuthenticated) {
        return (
            <div>
            </div>
        );
    } else
    return (
        <div>
            <div>

                {/*Messages submenu*/}
                <div>
                    <Menu
                        anchorEl={anchorMessagesEl}
                        id="messages-menu"
                        open={openMessages}
                        onClose={handleMessagesClose}
                        onClick={handleMessagesClose}
                        sx={{
                            '& .MuiPaper-root': {
                                backgroundColor: `${colors.primary[400]} !important`,
                            },
                        }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                bgcolor: '#ff000',
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '& .MuiMenuItem-root:hover': {
                                    color: "#868dfb !important",
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: `${colors.primary[400]} !important`,
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={() => handleRouterRedirect("/messages")}>
                            <ListItemIcon>
                                <CommentOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            {t("topBar.messages")}
                        </MenuItem>
                        <MenuItem onClick={() => handleRouterRedirect("/notifications")}>
                            <ListItemIcon>
                                <NotificationsOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            {t("topBar.notifications")}
                        </MenuItem>
                        <MenuItem onClick={() => handleRouterRedirect("/messages/new")}>
                            <ListItemIcon>
                                <AddCommentOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            {t("topBar.newMessage")}
                        </MenuItem>
                    </Menu>
                </div>

                {/*Settings submenu*/}
                <div>
                    <Menu
                        anchorEl={anchorSettingsEl}
                        id="settings-menu"
                        open={openSettings}
                        onClose={handleSettingsClose}
                        onClick={handleSettingsClose}
                        sx={{
                            '& .MuiList-root': {
                                backgroundColor: `${colors.primary[400]} !important`,
                            },
                        }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                bgcolor: '#ff000',
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '& .MuiMenuItem-root:hover': {
                                    color: "#868dfb !important",
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: `${colors.primary[400]} !important`,
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >

                        <MenuItem  onClick={() => handleRouterRedirect("/settings/application")}>
                            <ListItemIcon>
                                <TuneOutlinedIcon fontSize="small" />
                            </ListItemIcon>
                            {t("topBar.settings.application")}
                        </MenuItem>
                        <MenuItem  onClick={() => handleRouterRedirect("/settings/server")}>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            {t("topBar.settings.server")}
                        </MenuItem>
                        <MenuItem  onClick={() => handleRouterRedirect("/settings/init")}>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            {t("topBar.settings.init")}
                        </MenuItem>
                    </Menu>
                </div>

                {/*Account submenu*/}
                <div>
                    <Menu
                        anchorEl={anchorAccountEl}
                        id="account-menu"
                        open={openAccount}
                        onClose={handleAccountClose}
                        onClick={handleAccountClose}
                        sx={{
                            '& .MuiList-root': {
                                backgroundColor: `${colors.primary[400]} !important`,
                            },
                        }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                bgcolor: '#ff000',
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '& .MuiMenuItem-root:hover': {
                                    color: "#868dfb !important",
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: `${colors.primary[400]} !important`,
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleAccountClose} disabled={"disabled"}>
                            {auth?.user?.profile?.email}
                        </MenuItem>
                        <MenuItem onClick={handleAccountClose}>
                            <Avatar alt={auth?.user?.profile?.name} src={`../../assets/no-photo.jpeg`}/>
                            {t("topBar.profile")}
                        </MenuItem>
                        <MenuItem onClick={handleAccountClose}>
                            <Avatar alt={auth?.user?.profile?.name} src={`../../assets/no-photo.jpeg`}/>
                            {t("topBar.myAccount")}
                        </MenuItem>
                        <Divider />
                        <MenuItem  onClick={() => handleRouterRedirect("/users/create")}>
                            <ListItemIcon>
                                <PersonAdd fontSize="small" />
                            </ListItemIcon>
                            {t("topBar.addAccount")}
                        </MenuItem>
                        <MenuItem  onClick={() => handleRouterRedirect("/users/info")}>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            {t("topBar.accountSettings")}
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            {t("topBar.logout")}
                        </MenuItem>
                    </Menu>
                </div>
            </div>

            <Box display="flex" justifyContent="space-between" p={2}>
                <Box display="flex" justifyContent="space-between">
                    {/* SEARCH BAR */}
                    <Box
                        display="flex"
                        backgroundColor={colors.primary[400]}
                        borderRadius="3px"
                    >
                        <InputBase sx={{ml: 2, flex: 1}} placeholder={t("topBar.search")}/>
                        <IconButton type="button" sx={{p: 1}}>
                            <SearchIcon/>
                        </IconButton>
                    </Box>
                    <Box ml={2}>
                        <FormControl>
                            <Select
                                value={currencyValueContext}
                                onChange={handleChangeCurrency}
                                displayEmpty
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            // backgroundColor: `${colors.redAccent[400]} !important`,
                                            '& .MuiList-root': {
                                                backgroundColor: `${colors.primary[400]} !important`,
                                            },
                                        },
                                    },
                                }}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={"EUR"}>EUR</MenuItem>
                                <MenuItem value={"USD"}>USD</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box ml={2}>
                        <FormControl>
                            <Select
                                value={selectedLanguage}
                                onChange={chooseLanguage}
                                displayEmpty
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            // backgroundColor: `${colors.redAccent[400]} !important`,
                                            '& .MuiList-root': {
                                                backgroundColor: `${colors.primary[400]} !important`,
                                            },
                                        },
                                    },
                                }}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={"en"}>English</MenuItem>
                                <MenuItem value={"de"}>Deutsch</MenuItem>
                                <MenuItem value={"ru"}>Русский</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {/* ICONS */}
                <Box display="flex">
                    <IconButton onClick={colorMode.toggleColorMode}>
                        {theme.palette.mode === "dark" ? (
                            <LightModeOutlinedIcon/>
                        ) : (
                            <DarkModeOutlinedIcon/>
                        )}
                    </IconButton>
                    <IconButton onClick={handleMessagesClick}>
                        <NotificationsOutlinedIcon/>
                    </IconButton>
                    <IconButton onClick={handleSettingsClick}>
                        <SettingsOutlinedIcon/>
                    </IconButton>
                    <IconButton onClick={handleAccountClick}>
                        <PersonOutlinedIcon/>
                    </IconButton>
                </Box>
            </Box>
        </div>
    );
};

export default Topbar;
