export const mockDataOptions = [
  {
    id: 1,
    metal: "GOLD",
    oz: "1000.0",
    optionType: "CALL",
    price: "10.7",
    partner: "8",
    days: 365,
    strike: "21.78",
    curs: "19.19",
    primeProOz: "31.1",
    strategy: "STR AU",
    effectiveStrike: "111.77",
    tradeType: "SELL",
    date: "03.12.2022",
  },
  {
    id: 2,
    metal: "SILVER",
    oz: "1000.0",
    optionType: "PUT",
    price: "3.7",
    partner: "8",
    days: 365,
    strike: "12.78",
    curs: "5.19",
    primeProOz: "31.1",
    strategy: "STR SLV",
    effectiveStrike: "111.77",
    tradeType: "SELL",
    date: "21.12.2022",
  },
  {
    id: 3,
    metal: "GOLD",
    oz: "1000.0",
    optionType: "PUT",
    price: "10.7",
    partner: "8",
    days: 365,
    strike: "21.78",
    curs: "19.19",
    primeProOz: "31.1",
    strategy: "STR AU",
    effectiveStrike: "111.77",
    tradeType: "SELL",
    date: "03.12.2022",
  },
  {
    id: 4,
    metal: "PLATINUM",
    oz: "1000.0",
    optionType: "CALL",
    price: "10.7",
    partner: "8",
    days: 365,
    strike: "21.78",
    curs: "19.19",
    primeProOz: "31.1",
    strategy: "STR AU",
    effectiveStrike: "111.77",
    tradeType: "SELL",
    date: "03.12.2022",
  }
];


export const mockDataTeam = [
  {
    id: 3,
    name: "Vadim Chernov",
    email: "vadchernov@gmail.com",
    age: 20,
    phone: "+49 1520 888888",
    access: "admin",
  }
];

export const mockDataContacts = [
   {
    id: 3,
    name: "Vadim Chernov",
    email: "vadchernov@gmail.com",
    age: 20,
    phone: "+49 1520 1338887",
    address: "Ludwig-Wilhelm-Platz, 9",
    city: "Baden-Baden",
    zipCode: "76530",
    registrarId: 123321,
  }
];




/** =================================================================================== */



export const mockDataMetalsBalances = [
  {
    id: 1,
    metal: "Gold",
    weight: "Total",
    count: "5000.00",
  },
  {
    id: 7,
    metal: "Silver",
    weight: "Total",
    count: "5000.00",
  },
  {
    id: 13,
    metal: "Platinum",
    weight: "Total",
    count: "5000.00",
  },
  {
    id: 19,
    metal: "Palladium",
    weight: "Total",
    count: "5000.00",
  },


  {
    id: 2,
    metal: "Gold",
    weight: "1",
    count: "1000.00",
  },
  {
    id: 3,
    metal: "Gold",
    weight: "5",
    count: "200.00",
  },
  {
    id: 4,
    metal: "Gold",
    weight: "10",
    count: "100.00",
  },
  {
    id: 5,
    metal: "Gold",
    weight: "20",
    count: "50.00",
  },
  {
    id: 6,
    metal: "Gold",
    weight: "100",
    count: "10.00",
  },

  {
    id: 8,
    metal: "Silver",
    weight: "1",
    count: "1000.00",
  },
  {
    id: 9,
    metal: "Silver",
    weight: "5",
    count: "200.00",
  },
  {
    id: 10,
    metal: "Silver",
    weight: "10",
    count: "100.00",
  },
  {
    id: 11,
    metal: "Silver",
    weight: "20",
    count: "50.00",
  },
  {
    id: 12,
    metal: "Silver",
    weight: "100",
    count: "10.00",
  },

  {
    id: 14,
    metal: "Platinum",
    weight: "1",
    count: "1000.00",
  },
  {
    id: 15,
    metal: "Platinum",
    weight: "5",
    count: "200.00",
  },
  {
    id: 16,
    metal: "Platinum",
    weight: "10",
    count: "100.00",
  },
  {
    id: 17,
    metal: "Platinum",
    weight: "20",
    count: "50.00",
  },
  {
    id: 18,
    metal: "Platinum",
    weight: "100",
    count: "10.00",
  },

  {
    id: 20,
    metal: "Palladium",
    weight: "1",
    count: "1000.00",
  },
  {
    id: 21,
    metal: "Palladium",
    weight: "5",
    count: "200.00",
  },
  {
    id: 22,
    metal: "Palladium",
    weight: "10",
    count: "100.00",
  },
  {
    id: 23,
    metal: "Palladium",
    weight: "20",
    count: "50.00",
  },
  {
    id: 24,
    metal: "Palladium",
    weight: "100",
    count: "10.00",
  }
];

export const mockActions = [
  {
    actionId: "02df53",
    metal: "AU",
    cost: "43.95",
    oz: "1000.00",
    type: "CALL",
    date: "27.12.2022",
  },
  {
    actionId: "02df53",
    metal: "PT",
    cost: "41.95",
    oz: "1000.00",
    type: "PUT",
    date: "18.12.2022",
  },
  {
    actionId: "02df53",
    metal: "AU",
    cost: "47.95",
    oz: "2000.00",
    type: "CALL",
    date: "17.12.2022",
  },
  {
    actionId: "02df53",
    metal: "AG",
    cost: "4.95",
    oz: "1000.00",
    type: "CALL",
    date: "07.12.2022",
  }
];


