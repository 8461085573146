import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "react-oidc-context";


import {AUTH_ENDPOINT, AUTH_REDIRECT_URI, AUTH_POST_LOGOUT_REDIRECT_URI} from "./config/Env";

const onSigninCallback = () => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    )
}

const oidcConfig = {
    // authority: "https://keycloak.fwb.iextrem.com/realms/optionsapp-realm",
    authority: AUTH_ENDPOINT,
    client_id: "optionsapp-client",
    // redirect_uri: "http://localhost:5000",
    redirect_uri: AUTH_REDIRECT_URI,
    automaticSilentRenew: true,
    onSigninCallback: onSigninCallback,
    loadUserInfo: true,
    // postLogoutRedirectUri: "http://localhost:5000/",
    // revokeAccessTokenOnSignout: true,
    includeIdTokenInSilentSignout: true,

    // popup_post_logout_redirect_uri: "http://localhost:5000",
    // post_logout_redirect_uri: "http://localhost:5000",
    post_logout_redirect_uri: AUTH_POST_LOGOUT_REDIRECT_URI,
    popup_post_logout_redirect_uri: AUTH_POST_LOGOUT_REDIRECT_URI
};

console.log(AUTH_ENDPOINT);
console.log(AUTH_REDIRECT_URI);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <AuthProvider {...oidcConfig} >
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </AuthProvider>
);
