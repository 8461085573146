import {Box, Typography, useTheme} from "@mui/material";
import {tokens} from "../theme";
import ProgressCircle from "./ProgressCircle";
import { NumericFormat } from 'react-number-format';


const StatBox = ({title, subtitle, date, icon, progress, price, deltaPrice, currencySymbol}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box width="100%" m="0 30px">
            <Box display="flex" justifyContent="space-between">
                <Box>
                    {icon}
                    <Typography
                        variant="h4"
                        fontWeight="bold"
                        sx={{color: colors.grey[100]}}
                    >
                        {title}
                    </Typography>
                </Box>
                <Box>
                    <ProgressCircle progress={progress}/>
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" mt="2px">
                <Typography variant="h4" sx={{color: colors.greenAccent[500]}}>
                    <NumericFormat value={price} suffix={' ' + currencySymbol + ' '} thousandSeparator=" " decimalSeparator="," decimalScale={2} displayType="text" />
                </Typography>
                <Typography
                    variant="h5"
                    fontStyle="italic"
                    sx={{color: deltaPrice >= 0 ? colors.greenAccent[600] : colors.redAccent[500]}}
                >
                    <NumericFormat value={100*deltaPrice/price} suffix={'%  '} thousandSeparator=" " decimalSeparator="," decimalScale={2} displayType="text" />
                    <NumericFormat value={deltaPrice} suffix={' ' + currencySymbol + ' '} thousandSeparator=" " decimalSeparator="," decimalScale={2} displayType="text" />
                </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" mt="2px">
                <Typography variant="h5" sx={{color: colors.greenAccent[500]}}>
                    {date}
                </Typography>
            </Box>
        </Box>
    );
};

export default StatBox;
