import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Typography from "@mui/material/Typography";
import { tokens } from "../../theme";

const Messages = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="Messages" subtitle="List of Messages" />

        <Typography
            variant="h4"
            color={colors.grey[200]}
            fontWeight="700"
            sx={{ m: "25px 0 15px 0" }}
        >
        There aren't any new messages
      </Typography>

    </Box>
  );
};

export default Messages;
