import {useTheme} from "@mui/material";
import {ResponsiveLine} from "@nivo/line";
import {tokens} from "../theme";
import {useEffect, useState} from "react";
import axios from "axios";
import {useAuth} from "react-oidc-context";
import {useTranslation} from "react-i18next";

const MetalsLineChartComponent = ({isCustomLineColors = false, isDashboard = false, metal = "Gold", currency = "EUR", period = 30}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [chartData, setChartData] = useState([]);
    const url = process.env.REACT_APP_API_ENDPOINT + "/rate/latest/" + metal + "/" + period + "?currency=" + currency + "&type=line";
    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        axios.get(url, authConfig)
            .then((response) => {
                console.log(response);
                const data = response.data;
                if (metal === 'Gold') {
                    data[0].color = tokens("dark").greenAccent[500];
                } else if (metal === 'Silver') {
                    data[0].color = tokens("dark").blueAccent[500];
                } else if (metal === 'Platinum') {
                    data[0].color = tokens("dark").redAccent[500];
                } else if (metal === 'Palladium') {
                    data[0].color = tokens("dark").grey[400];
                } else if (metal === 'EUR' || metal === 'USD') {
                    data[0].color = tokens("dark").blueAccent[500];
                }
                // chartData = data;
                console.log(metal + " " + data[0].color);
                setChartData(data)
            }, (error) => {
                console.log(error);
                if (error.response.status === 401) {
                    auth.signinRedirect();
                }
            });
    }, [url, metal])

    let verticalTitle = t("chart.pricePerOz") + " " + currency;
    console.log(metal);
    if (metal === "EUR" || metal === "USD") {
        verticalTitle = metal + " " + t("chart.rateIn") + " " + currency;
    }

    return (
        <ResponsiveLine
            data={chartData}
            theme={{
                axis: {
                    domain: {
                        line: {
                            stroke: colors.grey[100],
                        },
                    },
                    legend: {
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                    ticks: {
                        line: {
                            stroke: colors.grey[100],
                            strokeWidth: 1,
                        },
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                },
                legends: {
                    text: {
                        fill: colors.grey[100],
                    },
                },
                tooltip: {
                    container: {
                        color: colors.primary[500],
                    },
                },
            }}
            colors={{datum: "color"}} // added
            margin={{top: 50, right: 30, bottom: 50, left: 60}}
            xScale={{type: "point"}}
            yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: true,
                reverse: false,
            }}
            yFormat=" >-.2f"
            curve="linear"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: "bottom",
                tickSize: 0,
                tickPadding: 10,
                tickRotation: -35,
                legend: isDashboard ? undefined : undefined, // added
                legendOffset: 50,
                legendPosition: "middle",
            }}
            axisLeft={{
                orient: "left",
                tickValues: 5, // added
                tickSize: 3,
                tickPadding: 3,
                tickRotation: 0,
                legend: isDashboard ? undefined : verticalTitle, // added
                legendOffset: -45,
                legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={8}
            pointColor={{theme: "background"}}
            pointBorderWidth={2}
            pointBorderColor={{from: "serieColor"}}
            pointLabelYOffset={-12}
            useMesh={true}
        />
    );
};

export default MetalsLineChartComponent;
