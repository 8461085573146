import {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import {Box, Button, TextField, useTheme} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import {tokens} from "../../theme";
import MenuItem from "@mui/material/MenuItem";
import {useAuth} from "react-oidc-context";
import axios from "axios";


const MetalsEditOrCreate = () => {
    const [formData, setFormData] = useState(null);
    const [formSaved, setFormSaved] = useState(false);
    const routeParams = useParams();
    const id = routeParams.id;
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT
    const urlId = apiEndpoint + "/metals/" + id;
    const url = apiEndpoint + "/metals";
    const disabledInput = id ? "disabled" : "";

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");


    const auth = useAuth();
    const authToken = auth.user.access_token;
    const authConfig = { headers: {Authorization: `Bearer ${authToken}`} };
    useEffect(() => {
        if (id) {
            axios.get(urlId, authConfig)
                .then((response) => {
                    console.log(response);
                    setFormData(response.data);
                }, (error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        auth.signinRedirect();
                    }
                });
        } else {
            setFormData(null);
        }
    }, [id, urlId])

    console.log("metal id: " + id);
    console.log(formData)

    const barTypes = [
        {
            value: '1',
            label: '1 oz',
        },
        {
            value: '2.5',
            label: '2.5 oz',
        },
        {
            value: '5',
            label: '5 oz',
        },
        {
            value: '10',
            label: '10 oz',
        },
        {
            value: '100',
            label: '100 oz',
        },
        {
            value: '400',
            label: '400 oz',
        }
    ];

    const metalTypes = [
        {
            value: 'GOLD',
            label: 'Gold',
        },
        {
            value: 'SILVER',
            label: 'Silver',
        },
        {
            value: 'PLATINUM',
            label: 'Platinum',
        },
        {
            value: 'PALLADIUM',
            label: 'Palladium',
        }
    ];


    function sendAjax(url, values, method) {
        axios({
            method: method,
            url: url,
            data: values,
            headers:  {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authToken
            }
        }).then((response) => {
            console.log(response);
            setFormData(response.data);
            setFormSaved(true);
            console.log(formSaved);
        }, (error) => {
            console.log(error);
            setFormSaved(false);
            console.error('There was an error!', error);
            if (error.response.status === 401) {
                auth.signinRedirect();
            }
        });
    }

    return (
        <Box m="20px">
            {id ? (<Header title="Edit Metal" subtitle="Edit Metal"/>) :
                (<Header title="Create New Metal" subtitle="Create New Metal"/>)
            }
            <Box
                sx={
                    {
                        "& input[type='date']::-webkit-calendar-picker-indicator": {
                            filter: 'invert(0.5)',
                        },
                        "& input[type='time']::-webkit-calendar-picker-indicator": {
                            filter: 'invert(0.5)',
                        },
                        "& .Mui-focused": {
                            color: colors.greenAccent[300],
                        },
                        "& label.Mui-focused": {
                            color: colors.greenAccent[300],
                        },
                        "& button.delete": {
                            backgroundColor: colors.redAccent[300],
                        },
                    }}
            >
                <Formik
                    onSubmit={(values, event) => {
                        if (id) {
                            sendAjax(urlId, values, "PUT");
                        } else {
                            sendAjax(url, values, "POST");
                        }

                    }}
                    enableReinitialize
                    initialValues={formData || initialValues}
                    validationSchema={checkoutSchema}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          isSubmitting,
                          handleSubmit,
                          dirty,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                                }}
                            >
                                <TextField
                                    id="filled-select-access-level"
                                    disabled={disabledInput}
                                    select
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Metal"
                                    InputLabelProps={{shrink: true}}
                                    defaultValue="GOLD"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.metal || ""}
                                    name="metal"
                                    error={!!touched.metal && !!errors.metal}
                                    helperText={touched.metal && errors.metal}
                                    sx={{gridColumn: "span 1"}}
                                >
                                    {metalTypes.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    id="filled-select-access-level"
                                    disabled={disabledInput}
                                    select
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Weight"
                                    InputLabelProps={{shrink: true}}
                                    defaultValue="1"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.bar || ""}
                                    name="bar"
                                    error={!!touched.bar && !!errors.bar}
                                    helperText={touched.bar && errors.bar}
                                    sx={{gridColumn: "span 1"}}
                                >
                                    {barTypes.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Quantity"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.quantity || ""}
                                    name="quantity"
                                    error={!!touched.quantity && !!errors.quantity}
                                    helperText={touched.quantity && errors.quantity}
                                    sx={{gridColumn: "span 1"}}
                                />
                                <TextField
                                    fullWidth
                                    disabled
                                    variant="filled"
                                    type="text"
                                    label="Id"
                                    InputLabelProps={{shrink: true}}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.id || ""}
                                    name="id"
                                    error={!!touched.id && !!errors.id}
                                    helperText={touched.id && errors.id}
                                    sx={{gridColumn: "span 1"}}
                                />
                            </Box>
                            <Box display="flex" justifyContent="space-between" mt="20px">
                                <Box>
                                    <Button type="submit" color="button" variant="contained" disabled={!dirty}>
                                        {id ? "Save Metal" : "Create New Metal"}
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

const checkoutSchema = yup.object().shape({
    metal: yup.string().required("required"),
    bar: yup.string().required("required"),
    quantity: yup.number().required("required"),
});

const initialValues = {
    metal: "GOLD",
    bar: "1",
    quantity: "1",
    uid: ""
};

export default MetalsEditOrCreate;
